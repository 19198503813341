import React from 'react'

const Team = ({ props }) => {

    if (!props) return;

    const { team } = props;
    let delay = 0;
    return (
        <div className="bg-white py-6 sm:py-8 lg:py-12">
            <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
                <div className="mb-10 md:mb-16">
                    <h2 className="mb-4 text-2xl font-extrabold text-gray-800 md:mb-6 lg:text-4xl text-center">NASZ ZESPÓŁ</h2>

                    <p className="mx-auto max-w-screen-md md:text-center font-light text-gray-500 sm:text-lg text-justify">Nasz zespół składa się z doświadczonych specjalistów posiadających różnorodne specjalizacje w terapii uzależnień. Zaufaj naszej wiedzy i zaangażowaniu w pomoc na każdym etapie Twojej drogi do zdrowia i wolności od nałogów.</p>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:gap-x-8 lg:gap-y-12 gap-x-4 gap-y-8">

                    {team.map((element) => {
                        delay+=100;
                        return (
                            <div 
                            data-aos="fade-up"
                            data-aos-delay={delay}
                            data-aos-offset="50"
                            key={element.id}>
                                <div className="relative aspect-square overflow-hidden">
                                    <img src={element.avatar} loading="lazy" alt={element.fullName} className="h-full w-full object-cover object-center relative z-10" />
                                    <div className='absolute z-20 bottom-0 left-0 right-0 px-4 py-6 bg-lime-600 bg-opacity-95'>
                                        <div className="font-bold text-white md:text-lg">{element.fullName}</div>
                                        <p className="text-sm text-gray-50 md:text-base">{element.position}</p>
                                    </div>
                                </div>

                                <div>


                                    {/* <div className="flex">
                                        <div className="flex gap-4">
                                            <a href="#" target="_blank" className="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
                                                <svg className="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                                                </svg>
                                            </a>

                                            <a href="#" target="_blank" className="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
                                                <svg className="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                                                </svg>
                                            </a>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        )
                    })}

                </div>

            </div>
        </div>
    )
}

export default Team