import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const Features = ({ props }) => {

    if (!props) return;

    const { title, subTitle, content } = props;

    const createLink = (category, link) => {

        if(category.length > 0){
            return "/" + category + "/" + link;
        }

        return link
    }

    let delay = 0;

    return (
        <div className="bg-white py-6 sm:py-8 lg:py-12">
            <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
                <div className="mb-10 md:mb-16">
                    <h2 className="mb-4 text-2xl font-extrabold text-gray-800 md:mb-6 lg:text-4xl text-center">{title}</h2>

                    <p className="mx-auto max-w-screen-md text-center font-light text-gray-500 sm:text-lg text-justify md:text-center">{subTitle}</p>
                </div>

                <div className="grid gap-4 sm:grid-cols-2 md:gap-8 xl:grid-cols-3">
                    {content.map(element => {
                        delay+=100;
                        return (
                            <div
                            data-aos="fade-up"
                            data-aos-delay={delay}
                            data-aos-offset="100"
                                style={{
                                    background: `url("${element.image || ""}")`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center'
                                }}
                                key={element.id} className="aspect-square border">
                                <div className='backdrop-blur-sm bg-black/30 w-full h-full flex flex-col items-center justify-center text-center p-4 md:p-6'>
                                    <h3 className="mb-2 text-lg text-white font-bold  md:text-3xl uppercase">{element.title}</h3>
                                    <p className="mb-4 py-16 font-light text-white sm:text-lg">{element.content}</p>
                                    <Link to={createLink(element.category,element.link)} className="inline-flex items-center font-semibold tracking-wider text-lime-100 transition-colors duration-200 hover:text-lime-300">
                                        {element.action}
                                        <div className='pl-2 pt-1'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                                            </svg>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Features