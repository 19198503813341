import React from 'react'
import { Helmet } from 'react-helmet'
import { Config } from '../../config/Config'
import { Footer, GalleryImages, NavBar, SimpleCta, SimpleHero, VideoPlayer } from '../../components/components'
import data from '../../data/navigation.tsx'

const Gallery = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>AlkoMedic.pl - Galeria</title>
        <link rel="canonical" href={Config.baseURL + window.location.pathname} />
      </Helmet>
      <NavBar current={"/galeria"} props={data} />
      <SimpleHero props={
        {
          title: "Galeria",
          span: "Zapraszamy do obejrzenia naszej galerii, gdzie można zobaczyć wnętrza ośrodka i otaczającą go spokojną naturę, które wspierają proces zdrowienia. Poznaj miejsca, w których dbamy o komfort i spokój naszych pacjentów.",
          image: "https://nowanadzieja.ct8.pl/upload/6.png"
        }
      } />

      <div className="mt-24">
        <VideoPlayer source={"https://nowanadzieja.ct8.pl/upload/movie.mp4"} />
      </div>

      <GalleryImages props={{
        title: "CO OFERUJE NASZ OŚRODEK",
        subTitle: "",
        images: [
          {
            id: "1",
            src: 'https://nowanadzieja.ct8.pl/upload/1.png',
            span: 'Lorem ipsum'
          },
          {
            id: "2",
            src: 'https://nowanadzieja.ct8.pl/upload/2.png',
            span: 'Lorem ipsum'
          },
          {
            id: "3",
            src: 'https://nowanadzieja.ct8.pl/upload/3.png',
            span: 'Lorem ipsum'
          },
          {
            id: "4",
            src: 'https://nowanadzieja.ct8.pl/upload/4.png',
            span: 'Lorem ipsum'
          },
          {
            id: "5",
            src: 'https://nowanadzieja.ct8.pl/upload/5.png',
            span: 'Lorem ipsum'
          },
          {
            id: "6",
            src: 'https://nowanadzieja.ct8.pl/upload/6.png',
            span: 'Lorem ipsum'
          },
          {
            id: "7",
            src: 'https://nowanadzieja.ct8.pl/upload/20.png',
            span: 'Lorem ipsum'
          },
          {
            id: "8",
            src: 'https://nowanadzieja.ct8.pl/upload/22.png',
            span: 'Lorem ipsum'
          },
          {
            id: "9",
            src: 'https://nowanadzieja.ct8.pl/upload/23.png',
            span: 'Lorem ipsum'
          },
          {
            id: "10",
            src: 'https://nowanadzieja.ct8.pl/upload/31.png',
            span: 'Lorem ipsum'
          },
          {
            id: "11",
            src: 'https://nowanadzieja.ct8.pl/upload/32.png',
            span: 'Lorem ipsum'
          },
          {
            id: "12",
            src: 'https://nowanadzieja.ct8.pl/upload/33.png',
            span: 'Lorem ipsum'
          },
          {
            id: "13",
            src: 'https://nowanadzieja.ct8.pl/upload/34.png',
            span: 'Lorem ipsum'
          },
          {
            id: "14",
            src: 'https://nowanadzieja.ct8.pl/upload/35.png',
            span: 'Lorem ipsum'
          },
          {
            id: "15",
            src: 'https://nowanadzieja.ct8.pl/upload/36.png',
            span: 'Lorem ipsum'
          },
          {
            id: "16",
            src: 'https://nowanadzieja.ct8.pl/upload/37.png',
            span: 'Lorem ipsum'
          },
        ]
      }} />

      <SimpleCta props={
        {
          title: "Dołącz do nas i zacznij odzyskiwać kontrolę nad swoim życiem",
          subTitle: "Zachęcamy Cię do dołączenia do naszej społeczności, gdzie razem możemy rozpocząć Twoją drogę do zdrowia i wolności od uzależnień. Daj sobie szansę na profesjonalną pomoc i wsparcie dostosowane specjalnie do Twoich potrzeb.",
        }
      } />
      <Footer />
    </>
  )
}

export default Gallery