import React from 'react'
import { Alert, Button } from '../../components'

const SimpleCta = ({ props }) => {

    if (!props) {
        return (
            <div className='px-24 mx-auto'>
                <Alert title='Error' message='Missing props in SimpleCta component.' type='error' visible={true} />
            </div>
        )
    }

    const { title, subTitle } = props;

    return (
        <div className="relative">
            <img src="https://images.unsplash.com/photo-1576014131795-d440191a8e8b?q=80&w=2071&auto=divat&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" className="absolute inset-0 h-full w-full object-cover" alt="" />
            <div className="relative bg-black bg-opacity-25">
                <div className="absolute inset-x-0 bottom-0">
                    <svg viewBox="0 0 224 12" fill="#fff" className="w-full -mb-1 text-white" preserveAspectRatio="none">
                        <path
                            d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z">
                        </path>
                    </svg>
                </div>
                <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="relative max-w-2xl sm:mx-auto sm:max-w-xl md:max-w-2xl sm:text-center">
                        <h2 className="mb-6 text-2xl font-extrabold text-white md:mb-6 lg:text-4xl text-center">
                            {title}
                        </h2>
                        <p className="mb-6 max-w-xl text-base text-gray-200 md:text-lg mx-auto">
                            {subTitle}
                        </p>
                        <div className="flex flex-col gap-2  w-full mb-4 md:flex-row md:px-16">
                            <input
                                placeholder="Email"
                                required=""
                                type="text"
                                className="mb-2 h-12 w-full flex-grow appearance-none rounded border border-gray-300 bg-white px-4 shadow-sm ring-lime-200 transition duration-200 focus:border-lime-400 focus:outline-none focus:ring"
                            />
                            <div className='lg:w-1/3'>
                                <Button label="Zapisz się" />
                            </div>
                        </div>
                        
                        {/* <a href="#" aria-label="Scroll down"
                            className="flex items-center justify-center w-10 h-10 mx-auto text-white duration-300 transdiv border border-gray-400 rounded-full hover:text-lime-accent-400 hover:border-lime-accent-400 hover:shadow hover:scale-110">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="currentColor">
                                <path
                                    d="M10.293,3.293,6,7.586,1.707,3.293A1,1,0,0,0,.293,4.707l5,5a1,1,0,0,0,1.414,0l5-5a1,1,0,1,0-1.414-1.414Z">
                                </path>
                            </svg>
                        </a> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SimpleCta