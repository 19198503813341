import React from 'react'

import z1 from '../../../assets/images/2.png'
import z2 from '../../../assets/images/21.png'

const ContentWithImage = () => {
  return (
    <section className="bg-white">
      <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-12 lg:px-6">
        <div className="font-light text-gray-500 sm:text-lg">
          <h2 className="mb-4 text-2xl font-extrabold text-gray-800 md:mb-6 lg:text-4xl">Kilka słów o nas</h2>
          <p className="mb-4 text-justify	">Jesteśmy prywatnym ośrodkiem specjalizującym się w leczeniu uzależnień, który kładzie szczególny nacisk na indywidualne podejście do każdego pacjenta. Nasz wyspecjalizowany zespół terapeutów i psychologów oferuje kompleksową opiekę oraz wsparcie na każdym etapie trudnej drogi do zdrowia i wolności od nałogów. Dzięki naszemu doświadczeniu i zaangażowaniu, zapewniamy skuteczne programy terapeutyczne, które pomagają odzyskać równowagę życiową i uwolnić się od uzależnień. Nasze podejście oparte jest na empatii i profesjonalizmie, co sprawia, że każdy klient może liczyć na indywidualnie dostosowaną terapię i wsparcie.
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-8">
          <img className="w-full h-[395px] object-cover hidden sm:block" src={z1} alt="office content 1"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-offset="100"
          />
          <img className="mt-4 w-full h-[395px] lg:mt-10 object-cover " src={z2} alt="office content 2"
            data-aos="fade-up"
            data-aos-delay="250"
            data-aos-offset="100"
          />
        </div>
      </div>
    </section>
  )
}

export default ContentWithImage