import React from 'react'
import { Link } from 'react-router-dom';
import { Config } from '../../../config/Config';

const PricingSimple = ({ props }) => {


    if (!props) return;

    const { title, span, price, table } = props;
    const getGridColsClass = (sizeOfRow) => {
        return {
            1: 'grid-cols-1',
            2: 'grid-cols-2',
            3: 'grid-cols-3',
            4: 'grid-cols-4',
            5: 'grid-cols-5',
            6: 'grid-cols-6',
        }[sizeOfRow] || 'grid-cols-1'; // Default to 1 column if no match
    };

    return (
        <div className="bg-white py-2 sm:py-2">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                {/* <div className="mx-auto max-w-2xl sm:text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Simple no-tricks pricing</h2>
                    <p className="mt-6 text-lg leading-8 text-gray-600">Distinctio et nulla eum soluta et neque labore quibusdam. Saepe et quasi iusto modi velit ut non voluptas in. Explicabo id ut laborum.</p>
                </div> */}
                <div className="mx-auto mt-16 max-w-2xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex flex-col lg:max-w-none">
                    <div className="p-8 sm:p-10 lg:flex-auto">
                        <h3 className="text-2xl font-bold tracking-tight text-gray-900">{title}</h3>
                        <p className="mt-4 leading-relaxed text-gray-500 font-light xl:text-lg">{span}</p>
                        <div className="mt-10 flex items-center gap-x-4 mb-4">
                            <h4 className="flex-none text-sm font-semibold leading-6 text-lime-700">Co zawiera</h4>
                            <div className="h-px flex-auto bg-gray-100"></div>
                        </div>
                        {/* <ul role="list" className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
                            {offertItems.map((element) => {
                                return (
                                    <li className="flex gap-x-3">
                                        <svg className="h-6 w-5 flex-none text-lime-700" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                                        </svg>
                                        {element}
                                    </li>
                                )
                            })}
                        </ul> */}
                        <div className='bg-slate-50 border p-1 pb-0'>
                            {
                                table.map((element, key) => {
                                    const sizeOfRow = element.length;
                                    const gridColsClass = getGridColsClass(sizeOfRow);
                                    if (key === 0) {
                                        return (<div key={key} className={`grid ${gridColsClass} gap-1`}>
                                            {
                                                element.map((el, key) => {
                                                    console.log('el', el.includes('*'))
                                                    if (key === sizeOfRow - 1 && el.includes('*')) {
                                                        return (<div key={key} className='border bg-stone-600 text-white font-bold p-2 mb-1 leading-relaxed text-gray-500 xl:text-md'>{el.replace('*', '')}</div>)
                                                    } else {
                                                        return (<div key={key} className='border bg-lime-600 text-white font-bold p-2 mb-1 leading-relaxed text-gray-500 xl:text-md'>{el}</div>)
                                                    }
                                                })
                                            }
                                        </div>)
                                    } else {
                                        return (<div key={key} className={`grid ${gridColsClass} gap-1`}>
                                            {
                                                element.map((el, key) => {
                                                    if (key === sizeOfRow - 1 && el.includes('*')) {
                                                        return (<div key={key} className='flex items-center border bg-stone-500 p-2 mb-1 leading-relaxed text-white font-light xl:text-md'>{el.replace('*', '')}</div>)
                                                    } else {
                                                        return (<div key={key} className='flex items-center border bg-white p-2 mb-1 leading-relaxed text-gray-500 font-light xl:text-md'>{el}</div>)
                                                    }
                                                })
                                            }
                                        </div>)
                                    }
                                })
                            }
                        </div>
                    </div>

                    <div class="bg-white py-6 sm:py-8 lg:py-12">
                        <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
                            <div class="flex flex-col items-center justify-between gap-4  bg-gray-100 p-4 sm:flex-row md:p-8">
                                <div>
                                    <h2 class="text-xl font-bold md:text-2xl">Zadzwoń</h2>
                                    <p class="text-gray-600">Nie czekaj i umów się już dziś</p>
                                </div>

                                <a href="#" class="inline-block  bg-lime-600 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-lime-600 transition duration-100 hover:bg-lime-700 focus-visible:ring active:bg-lime-800 md:text-base">555-555-555</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default PricingSimple