const articles = {
    "alkoholizm": {
        "title": "Alkoholizm",
        "subTitle": "Alkoholizm to nie tylko problem z piciem, to choroba, która dotyka całe życie. Razem możemy z nią walczyć i przywrócić równowagę oraz zdrowie.",
        "contentImage": "https://images.unsplash.com/photo-1495399396117-a3763646f854?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "content": `<main>        
        <section>
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Spis treści</h2>
            <ul class="list-decimal my-4 mx-6">
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#wstep">Wstęp</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#alkoholizm-informacje">Alkoholizm – informacje</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-alkoholizmu-objawy">Leczenie alkoholizmu – objawy</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#jak-leczyc-alkoholika">Jak leczyć alkoholika?</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#terapia-alkoholowa">Terapia alkoholowa – skuteczne leczenie alkoholizmu</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#etapy-leczenia">Etapy leczenia</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-alkoholizmu-ile-trwa">Leczenie alkoholizmu – ile trwa?</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#sposoby-leczenia-alkoholizmu">Sposoby leczenia alkoholizmu</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-alkoholizmu-techniki">Leczenie alkoholizmu – stosowane techniki</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#przymusowe-leczenie-alkoholizmu">Przymusowe leczenie alkoholizmu</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-alkoholizmu-co-dalej">Leczenie alkoholizmu – i co dalej?</a></li>
            </ul>
        </section>
        
        <section class="mb-8" id="wstep">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Wstęp</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                <b>Alkoholizm</b> to choroba, która dotyka miliony ludzi na całym świecie. Jest to uzależnienie, które nie tylko niszczy zdrowie fizyczne, ale także ma ogromny wpływ na życie społeczne i emocjonalne osób uzależnionych oraz ich bliskich. W <b>AlkoMedic</b> wierzymy, że każdy zasługuje na szansę na zdrowie i pełne, szczęśliwe życie. Dlatego oferujemy kompleksowe i indywidualne podejście do leczenia alkoholizmu, które obejmuje zarówno terapię medyczną, jak i psychologiczną.
            </p>
        </section>
        
        <section class="mb-8" id="alkoholizm-informacje">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Alkoholizm – informacje</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Alkoholizm jest to chroniczna choroba, która charakteryzuje się niekontrolowanym spożywaniem alkoholu mimo negatywnych konsekwencji zdrowotnych i społecznych. Jest to zaburzenie, które rozwija się stopniowo, a jego objawy mogą różnić się w zależności od osoby. Alkoholizm nie jest wynikiem braku siły woli czy moralnego upadku, ale poważnym zaburzeniem, które wymaga specjalistycznej interwencji i leczenia.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-alkoholizmu-objawy">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie alkoholizmu – objawy</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Objawy alkoholizmu mogą obejmować zarówno fizyczne, jak i psychiczne symptomy. Fizyczne objawy to m.in. drżenie rąk, potliwość, problemy z wątrobą, sercem oraz układem trawiennym. Psychiczne objawy to m.in. lęk, depresja, zaburzenia snu, oraz zmiany w zachowaniu. Osoba uzależniona często zaniedbuje swoje obowiązki zawodowe i rodzinne, a także ma problemy z kontrolowaniem ilości spożywanego alkoholu. Rozpoznanie tych objawów jest kluczowe w procesie diagnozy i leczenia alkoholizmu.
            </p>
        </section>
        
        <section class="mb-8" id="jak-leczyc-alkoholika">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Jak leczyć alkoholika?</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Leczenie alkoholizmu to proces, który wymaga zaangażowania zarówno pacjenta, jak i jego bliskich. Pierwszym krokiem jest uznanie problemu i chęć podjęcia leczenia. Następnie, konieczna jest profesjonalna ocena stanu zdrowia pacjenta oraz dobranie odpowiedniej terapii. W <b>AlkoMedic</b> oferujemy szeroki wachlarz metod leczenia, które są dostosowane do indywidualnych potrzeb pacjenta. Nasze podejście obejmuje zarówno terapię farmakologiczną, jak i psychoterapię, a także wsparcie socjalne i edukacyjne.
            </p>
        </section>
        
        <section class="mb-8" id="terapia-alkoholowa">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Terapia alkoholowa – skuteczne leczenie alkoholizmu</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Skuteczne leczenie alkoholizmu wymaga kompleksowego podejścia, które uwzględnia zarówno fizyczne, jak i psychiczne aspekty uzależnienia. Terapia alkoholowa w <b>AlkoMedic</b> obejmuje kilka kluczowych etapów, które mają na celu pomóc pacjentowi w powrocie do zdrowia. Pierwszym etapem jest detoksykacja, która pozwala na usunięcie alkoholu z organizmu i złagodzenie objawów odstawienia. Kolejnym etapem jest terapia psychologiczna, która pomaga pacjentowi zrozumieć przyczyny uzależnienia i nauczyć się radzić sobie z trudnymi emocjami i sytuacjami bez sięgania po alkohol.
            </p>
        </section>
        
        <section class="mb-8" id="etapy-leczenia">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Etapy leczenia</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Leczenie alkoholizmu w <b>AlkoMedic</b> składa się z kilku etapów, które są dostosowane do indywidualnych potrzeb pacjenta. Pierwszym etapem jest detoksykacja, która polega na oczyszczeniu organizmu z alkoholu i złagodzeniu objawów odstawienia. Następnie, pacjent przechodzi przez terapię indywidualną i grupową, która ma na celu zrozumienie przyczyn uzależnienia i naukę radzenia sobie z trudnymi emocjami. Kolejnym etapem jest terapia rodzinna, która angażuje bliskich pacjenta w proces leczenia i wspiera odbudowę zdrowych relacji. Ostatnim etapem jest rehabilitacja, która pomaga pacjentowi w powrocie do pełnego życia i utrzymaniu trzeźwości.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-alkoholizmu-ile-trwa">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie alkoholizmu – ile trwa?</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Czas trwania leczenia alkoholizmu może się różnić w zależności od indywidualnych potrzeb pacjenta oraz stopnia zaawansowania uzależnienia. W <b>AlkoMedic</b> oferujemy zarówno krótkoterminowe, jak i długoterminowe programy leczenia, które są dostosowane do specyficznych potrzeb pacjenta. Krótkoterminowe programy mogą trwać od kilku tygodni do kilku miesięcy, podczas gdy długoterminowe programy mogą trwać nawet rok lub dłużej. Kluczowe jest, aby leczenie było kontynuowane tak długo, jak to konieczne, aby zapewnić trwałe rezultaty i zapobiec nawrotom.
            </p>
        </section>
        
        <section class="mb-8" id="sposoby-leczenia-alkoholizmu">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Sposoby leczenia alkoholizmu</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Leczenie alkoholizmu w <b>AlkoMedic</b> obejmuje różnorodne metody i techniki, które są dostosowane do indywidualnych potrzeb pacjenta. Wśród najczęściej stosowanych metod są:
                <ul class="list-disc my-4 mx-4">
                    <li class="font-light text-gray-500 sm:text-lg">Detoksykacja medyczna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia indywidualna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia grupowa</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia rodzinna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Wsparcie farmakologiczne</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia behawioralna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Programy rehabilitacyjne</li>
                </ul>
                Każdy z tych elementów leczenia jest kluczowy dla osiągnięcia trwałych rezultatów i zapewnienia pacjentowi szansy na zdrowe i pełne życie bez alkoholu.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-alkoholizmu-techniki">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie alkoholizmu – stosowane techniki</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                W <b>AlkoMedic</b> stosujemy różnorodne techniki terapeutyczne, które są dostosowane do indywidualnych potrzeb pacjenta. Wśród najskuteczniejszych technik znajdują się:
                <ul class="list-disc my-4 mx-4">
                    <li class="font-light text-gray-500 sm:text-lg">Terapia poznawczo-behawioralna (CBT)</li>
                    <li class="font-light text-gray-500 sm:text-lg">Motywacyjne Rozmowy Terapeutyczne</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia Gestalt</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia systemowa</li>
                    <li class="font-light text-gray-500 sm:text-lg">Program 12 kroków</li>
                    <li class="font-light text-gray-500 sm:text-lg">Techniki mindfulness</li>
                </ul>
                Każda z tych technik ma na celu pomoc pacjentowi w zrozumieniu przyczyn uzależnienia, naukę radzenia sobie z trudnymi emocjami oraz rozwój zdrowych nawyków i umiejętności życiowych.
            </p>
        </section>
        
        <section class="mb-8" id="przymusowe-leczenie-alkoholizmu">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Przymusowe leczenie alkoholizmu</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                W niektórych przypadkach przymusowe leczenie alkoholizmu może być konieczne, aby chronić zdrowie i życie pacjenta oraz jego bliskich. Przymusowe leczenie jest stosowane, gdy osoba uzależniona nie jest w stanie samodzielnie podjąć decyzji o rozpoczęciu terapii, a jej stan zdrowia zagraża życiu lub zdrowiu. W <b>AlkoMedic</b> oferujemy profesjonalne wsparcie w zakresie przymusowego leczenia, które jest realizowane zgodnie z obowiązującymi przepisami prawnymi i etycznymi. Naszym celem jest zapewnienie pacjentowi bezpiecznego i skutecznego leczenia, które pozwoli mu na powrót do zdrowia i normalnego życia.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-alkoholizmu-co-dalej">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie alkoholizmu – i co dalej?</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Po zakończeniu formalnego procesu leczenia, ważne jest, aby pacjent kontynuował swoją drogę do zdrowia i utrzymania trzeźwości. W <b>AlkoMedic</b> oferujemy wsparcie poszpitalne, które obejmuje regularne konsultacje z terapeutą, udział w grupach wsparcia oraz dostęp do programów rehabilitacyjnych. Ważnym elementem jest także edukacja pacjenta i jego rodziny na temat sposobów radzenia sobie z trudnościami i unikania nawrotów. Naszym celem jest zapewnienie pacjentowi kompleksowej opieki, która pomoże mu w utrzymaniu zdrowego i trzeźwego stylu życia na długie lata.
            </p>
        </section>
    </main>`
    },
    "narkomania": {
        "title": "Narkomania",
        "subTitle": "Narkomania to niebezpieczna pułapka, która niszczy życie. Nasza misja to wspólna walka i pomoc w odzyskaniu kontroli oraz zdrowia.",
        "contentImage": "https://images.unsplash.com/photo-1611690827904-0ebe4fe34bd8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "content": `<main>
        <section>
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Spis treści</h2>
            <ul class="list-decimal my-4 mx-4">
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#wstep">Wstęp</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#narkomania-informacje">Narkomania – informacje</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-narkomanii-objawy">Leczenie narkomanii – objawy</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#jak-leczyc-narkomana">Jak leczyć narkomana?</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#terapia-narkotykowa">Terapia narkotykowa – skuteczne leczenie narkomanii</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#etapy-leczenia">Etapy leczenia</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-narkomanii-ile-trwa">Leczenie narkomanii – ile trwa?</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#sposoby-leczenia-narkomanii">Sposoby leczenia narkomanii</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-narkomanii-techniki">Leczenie narkomanii – stosowane techniki</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#przymusowe-leczenie-narkomanii">Przymusowe leczenie narkomanii</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-narkomanii-co-dalej">Leczenie narkomanii – i co dalej?</a></li>
            </ul>
        </section>
        
        <section class="mb-8" id="wstep">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Wstęp</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Narkomania to poważna choroba, która dotyka wielu ludzi na całym świecie. Jest to uzależnienie od substancji psychoaktywnych, które niszczy zdrowie fizyczne i psychiczne oraz ma ogromny wpływ na życie społeczne i emocjonalne osób uzależnionych oraz ich bliskich. W AlkoMedic wierzymy, że każdy zasługuje na szansę na zdrowie i pełne, szczęśliwe życie. Dlatego oferujemy kompleksowe i indywidualne podejście do leczenia narkomanii, które obejmuje zarówno terapię medyczną, jak i psychologiczną.
            </p>
        </section>
        
        <section class="mb-8" id="narkomania-informacje">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Narkomania – informacje</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Narkomania to chroniczna choroba, która charakteryzuje się kompulsywnym używaniem narkotyków mimo negatywnych konsekwencji zdrowotnych i społecznych. Jest to zaburzenie, które rozwija się stopniowo, a jego objawy mogą różnić się w zależności od osoby oraz używanej substancji. Narkomania nie jest wynikiem braku siły woli czy moralnego upadku, ale poważnym zaburzeniem, które wymaga specjalistycznej interwencji i leczenia.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-narkomanii-objawy">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie narkomanii – objawy</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Objawy narkomanii mogą obejmować zarówno fizyczne, jak i psychiczne symptomy. Fizyczne objawy to m.in. zmiany w wyglądzie, utrata wagi, problemy ze snem, oraz problemy zdrowotne związane z konkretną substancją. Psychiczne objawy to m.in. lęk, depresja, zmiany w zachowaniu, oraz zaniedbywanie obowiązków zawodowych i rodzinnych. Osoba uzależniona często ma trudności z kontrolowaniem ilości spożywanej substancji i odczuwa silne pragnienie jej zażycia. Rozpoznanie tych objawów jest kluczowe w procesie diagnozy i leczenia narkomanii.
            </p>
        </section>
        
        <section class="mb-8" id="jak-leczyc-narkomana">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Jak leczyć narkomana?</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Leczenie narkomanii to proces, który wymaga zaangażowania zarówno pacjenta, jak i jego bliskich. Pierwszym krokiem jest uznanie problemu i chęć podjęcia leczenia. Następnie, konieczna jest profesjonalna ocena stanu zdrowia pacjenta oraz dobranie odpowiedniej terapii. W AlkoMedic oferujemy szeroki wachlarz metod leczenia, które są dostosowane do indywidualnych potrzeb pacjenta. Nasze podejście obejmuje zarówno terapię farmakologiczną, jak i psychoterapię, a także wsparcie socjalne i edukacyjne.
            </p>
        </section>
        
        <section class="mb-8" id="terapia-narkotykowa">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Terapia narkotykowa – skuteczne leczenie narkomanii</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Skuteczne leczenie narkomanii wymaga kompleksowego podejścia, które uwzględnia zarówno fizyczne, jak i psychiczne aspekty uzależnienia. Terapia narkotykowa w AlkoMedic obejmuje kilka kluczowych etapów, które mają na celu pomóc pacjentowi w powrocie do zdrowia. Pierwszym etapem jest detoksykacja, która pozwala na usunięcie substancji z organizmu i złagodzenie objawów odstawienia. Kolejnym etapem jest terapia psychologiczna, która pomaga pacjentowi zrozumieć przyczyny uzależnienia i nauczyć się radzić sobie z trudnymi emocjami i sytuacjami bez sięgania po narkotyki.
            </p>
        </section>
        
        <section class="mb-8" id="etapy-leczenia">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Etapy leczenia</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Leczenie narkomanii w AlkoMedic składa się z kilku etapów, które są dostosowane do indywidualnych potrzeb pacjenta. Pierwszym etapem jest detoksykacja, która polega na oczyszczeniu organizmu z narkotyków i złagodzeniu objawów odstawienia. Następnie, pacjent przechodzi przez terapię indywidualną i grupową, która ma na celu zrozumienie przyczyn uzależnienia i naukę radzenia sobie z trudnymi emocjami. Kolejnym etapem jest terapia rodzinna, która angażuje bliskich pacjenta w proces leczenia i wspiera odbudowę zdrowych relacji. Ostatnim etapem jest rehabilitacja, która pomaga pacjentowi w powrocie do pełnego życia i utrzymaniu trzeźwości.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-narkomanii-ile-trwa">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie narkomanii – ile trwa?</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Czas trwania leczenia narkomanii może się różnić w zależności od indywidualnych potrzeb pacjenta oraz stopnia zaawansowania uzależnienia. W AlkoMedic oferujemy zarówno krótkoterminowe, jak i długoterminowe programy leczenia, które są dostosowane do specyficznych potrzeb pacjenta. Krótkoterminowe programy mogą trwać od kilku tygodni do kilku miesięcy, podczas gdy długoterminowe programy mogą trwać nawet rok lub dłużej. Kluczowe jest, aby leczenie było kontynuowane tak długo, jak to konieczne, aby zapewnić trwałe rezultaty i zapobiec nawrotom.
            </p>
        </section>
        
        <section class="mb-8" id="sposoby-leczenia-narkomanii">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Sposoby leczenia narkomanii</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Leczenie narkomanii w AlkoMedic obejmuje różnorodne metody i techniki, które są dostosowane do indywidualnych potrzeb pacjenta. Wśród najczęściej stosowanych metod są:
                <ul class="list-disc my-4 mx-4">
                    <li class="font-light text-gray-500 sm:text-lg">Detoksykacja medyczna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia indywidualna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia grupowa</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia rodzinna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Wsparcie farmakologiczne</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia behawioralna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Programy rehabilitacyjne</li>
                </ul>
                Każdy z tych elementów leczenia jest kluczowy dla osiągnięcia trwałych rezultatów i zapewnienia pacjentowi szansy na zdrowe i pełne życie bez narkotyków.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-narkomanii-techniki">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie narkomanii – stosowane techniki</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                W AlkoMedic stosujemy różnorodne techniki terapeutyczne, które są dostosowane do indywidualnych potrzeb pacjenta. Wśród najskuteczniejszych technik znajdują się:
                <ul class="list-disc my-4 mx-4">
                    <li class="font-light text-gray-500 sm:text-lg">Terapia poznawczo-behawioralna (CBT)</li>
                    <li class="font-light text-gray-500 sm:text-lg">Motywacyjne Rozmowy Terapeutyczne</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia Gestalt</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia systemowa</li>
                    <li class="font-light text-gray-500 sm:text-lg">Program 12 kroków</li>
                    <li class="font-light text-gray-500 sm:text-lg">Techniki mindfulness</li>
                </ul>
                Każda z tych technik ma na celu pomoc pacjentowi w zrozumieniu przyczyn uzależnienia, naukę radzenia sobie z trudnymi emocjami oraz rozwój zdrowych nawyków i umiejętności życiowych.
            </p>
        </section>
        
        <section class="mb-8" id="przymusowe-leczenie-narkomanii">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Przymusowe leczenie narkomanii</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                W niektórych przypadkach przymusowe leczenie narkomanii może być konieczne, aby chronić zdrowie i życie pacjenta oraz jego bliskich. Przymusowe leczenie jest stosowane, gdy osoba uzależniona nie jest w stanie samodzielnie podjąć decyzji o rozpoczęciu terapii, a jej stan zdrowia zagraża życiu lub zdrowiu. W AlkoMedic oferujemy profesjonalne wsparcie w zakresie przymusowego leczenia, które jest realizowane zgodnie z obowiązującymi przepisami prawnymi i etycznymi. Naszym celem jest zapewnienie pacjentowi bezpiecznego i skutecznego leczenia, które pozwoli mu na powrót do zdrowia i normalnego życia.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-narkomanii-co-dalej">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie narkomanii – i co dalej?</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Po zakończeniu formalnego procesu leczenia, ważne jest, aby pacjent kontynuował swoją drogę do zdrowia i utrzymania trzeźwości. W AlkoMedic oferujemy wsparcie poszpitalne, które obejmuje regularne konsultacje z terapeutą, udział w grupach wsparcia oraz dostęp do programów rehabilitacyjnych. Ważnym elementem jest także edukacja pacjenta i jego rodziny na temat sposobów radzenia sobie z trudnościami i unikania nawrotów. Naszym celem jest zapewnienie pacjentowi kompleksowej opieki, która pomoże mu w utrzymaniu zdrowego i trzeźwego stylu życia na długie lata.
            </p>
        </section>
    </main>`
    },
    "lekomania": {
        "title": "Lekomania",
        "subTitle": "Lekomania to uzależnienie, które może zniszczyć zdrowie i życie. Wspólnie możemy przezwyciężyć ten problem i wrócić do pełni sił.",
        "contentImage": "https://images.unsplash.com/photo-1675524375058-0b0f72f5d3b8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        'content': `<main>        
        <section>
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Spis treści</h2>
            <ul class="list-decimal my-4 mx-4">
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#wstep">Wstęp</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#lekomania-informacje">Lekomania – informacje</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-lekomanii-objawy">Leczenie lekomanii – objawy</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#jak-leczyc-lekomana">Jak leczyć lekomana?</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#terapia-lekowa">Terapia lekowa – skuteczne leczenie lekomanii</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#etapy-leczenia">Etapy leczenia</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-lekomanii-ile-trwa">Leczenie lekomanii – ile trwa?</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#sposoby-leczenia-lekomanii">Sposoby leczenia lekomanii</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-lekomanii-techniki">Leczenie lekomanii – stosowane techniki</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#przymusowe-leczenie-lekomanii">Przymusowe leczenie lekomanii</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-lekomanii-co-dalej">Leczenie lekomanii – i co dalej?</a></li>
            </ul>
        </section>
        
        <section class="mb-8" id="wstep">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Wstęp</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Lekomania, znana również jako uzależnienie od leków, to poważna choroba, która dotyka wielu ludzi na całym świecie. Jest to uzależnienie od substancji farmakologicznych, które niszczy zdrowie fizyczne i psychiczne oraz ma ogromny wpływ na życie społeczne i emocjonalne osób uzależnionych oraz ich bliskich. W AlkoMedic wierzymy, że każdy zasługuje na szansę na zdrowie i pełne, szczęśliwe życie. Dlatego oferujemy kompleksowe i indywidualne podejście do leczenia lekomanii, które obejmuje zarówno terapię medyczną, jak i psychologiczną.
            </p>
        </section>
        
        <section class="mb-8" id="lekomania-informacje">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Lekomania – informacje</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Lekomania to chroniczna choroba, która charakteryzuje się kompulsywnym używaniem leków mimo negatywnych konsekwencji zdrowotnych i społecznych. Jest to zaburzenie, które rozwija się stopniowo, a jego objawy mogą różnić się w zależności od osoby oraz używanych substancji. Lekomania nie jest wynikiem braku siły woli czy moralnego upadku, ale poważnym zaburzeniem, które wymaga specjalistycznej interwencji i leczenia.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-lekomanii-objawy">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie lekomanii – objawy</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Objawy lekomanii mogą obejmować zarówno fizyczne, jak i psychiczne symptomy. Fizyczne objawy to m.in. zmiany w wyglądzie, utrata wagi, problemy ze snem, oraz problemy zdrowotne związane z konkretnymi lekami. Psychiczne objawy to m.in. lęk, depresja, zmiany w zachowaniu, oraz zaniedbywanie obowiązków zawodowych i rodzinnych. Osoba uzależniona często ma trudności z kontrolowaniem ilości spożywanych leków i odczuwa silne pragnienie ich zażycia. Rozpoznanie tych objawów jest kluczowe w procesie diagnozy i leczenia lekomanii.
            </p>
        </section>
        
        <section class="mb-8" id="jak-leczyc-lekomana">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Jak leczyć lekomana?</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Leczenie lekomanii to proces, który wymaga zaangażowania zarówno pacjenta, jak i jego bliskich. Pierwszym krokiem jest uznanie problemu i chęć podjęcia leczenia. Następnie, konieczna jest profesjonalna ocena stanu zdrowia pacjenta oraz dobranie odpowiedniej terapii. W AlkoMedic oferujemy szeroki wachlarz metod leczenia, które są dostosowane do indywidualnych potrzeb pacjenta. Nasze podejście obejmuje zarówno terapię farmakologiczną, jak i psychoterapię, a także wsparcie socjalne i edukacyjne.
            </p>
        </section>
        
        <section class="mb-8" id="terapia-lekowa">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Terapia lekowa – skuteczne leczenie lekomanii</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Skuteczne leczenie lekomanii wymaga kompleksowego podejścia, które uwzględnia zarówno fizyczne, jak i psychiczne aspekty uzależnienia. Terapia lekowa w AlkoMedic obejmuje kilka kluczowych etapów, które mają na celu pomóc pacjentowi w powrocie do zdrowia. Pierwszym etapem jest detoksykacja, która pozwala na usunięcie leków z organizmu i złagodzenie objawów odstawienia. Kolejnym etapem jest terapia psychologiczna, która pomaga pacjentowi zrozumieć przyczyny uzależnienia i nauczyć się radzić sobie z trudnymi emocjami i sytuacjami bez sięgania po leki.
            </p>
        </section>
        
        <section class="mb-8" id="etapy-leczenia">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Etapy leczenia</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Leczenie lekomanii w AlkoMedic składa się z kilku etapów, które są dostosowane do indywidualnych potrzeb pacjenta. Pierwszym etapem jest detoksykacja, która polega na oczyszczeniu organizmu z leków i złagodzeniu objawów odstawienia. Następnie, pacjent przechodzi przez terapię indywidualną i grupową, która ma na celu zrozumienie przyczyn uzależnienia i naukę radzenia sobie z trudnymi emocjami. Kolejnym etapem jest terapia rodzinna, która angażuje bliskich pacjenta w proces leczenia i wspiera odbudowę zdrowych relacji. Ostatnim etapem jest rehabilitacja, która pomaga pacjentowi w powrocie do pełnego życia i utrzymaniu trzeźwości.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-lekomanii-ile-trwa">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie lekomanii – ile trwa?</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Czas trwania leczenia lekomanii może się różnić w zależności od indywidualnych potrzeb pacjenta oraz stopnia zaawansowania uzależnienia. W AlkoMedic oferujemy zarówno krótkoterminowe, jak i długoterminowe programy leczenia, które są dostosowane do specyficznych potrzeb pacjenta. Krótkoterminowe programy mogą trwać od kilku tygodni do kilku miesięcy, podczas gdy długoterminowe programy mogą trwać nawet rok lub dłużej. Kluczowe jest, aby leczenie było kontynuowane tak długo, jak to konieczne, aby zapewnić trwałe rezultaty i zapobiec nawrotom.
            </p>
        </section>
        
        <section class="mb-8" id="sposoby-leczenia-lekomanii">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Sposoby leczenia lekomanii</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Leczenie lekomanii w AlkoMedic obejmuje różnorodne metody i techniki, które są dostosowane do indywidualnych potrzeb pacjenta. Wśród najczęściej stosowanych metod są:
                <ul class="list-disc my-4 mx-4">
                    <li class="font-light text-gray-500 sm:text-lg">Detoksykacja medyczna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia indywidualna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia grupowa</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia rodzinna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Wsparcie farmakologiczne</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia behawioralna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Programy rehabilitacyjne</li>
                </ul>
                Każdy z tych elementów leczenia jest kluczowy dla osiągnięcia trwałych rezultatów i zapewnienia pacjentowi szansy na zdrowe i pełne życie bez leków.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-lekomanii-techniki">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie lekomanii – stosowane techniki</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                W AlkoMedic stosujemy różnorodne techniki terapeutyczne, które są dostosowane do indywidualnych potrzeb pacjenta. Wśród najskuteczniejszych technik znajdują się:
                <ul class="list-disc my-4 mx-4">
                    <li class="font-light text-gray-500 sm:text-lg">Terapia poznawczo-behawioralna (CBT)</li>
                    <li class="font-light text-gray-500 sm:text-lg">Motywacyjne Rozmowy Terapeutyczne</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia Gestalt</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia systemowa</li>
                    <li class="font-light text-gray-500 sm:text-lg">Program 12 kroków</li>
                    <li class="font-light text-gray-500 sm:text-lg">Techniki mindfulness</li>
                </ul>
                Każda z tych technik ma na celu pomoc pacjentowi w zrozumieniu przyczyn uzależnienia, naukę radzenia sobie z trudnymi emocjami oraz rozwój zdrowych nawyków i umiejętności życiowych.
            </p>
        </section>
        
        <section class="mb-8" id="przymusowe-leczenie-lekomanii">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Przymusowe leczenie lekomanii</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                W niektórych przypadkach przymusowe leczenie lekomanii może być konieczne, aby chronić zdrowie i życie pacjenta oraz jego bliskich. Przymusowe leczenie jest stosowane, gdy osoba uzależniona nie jest w stanie samodzielnie podjąć decyzji o rozpoczęciu terapii, a jej stan zdrowia zagraża życiu lub zdrowiu. W AlkoMedic oferujemy profesjonalne wsparcie w zakresie przymusowego leczenia, które jest realizowane zgodnie z obowiązującymi przepisami prawnymi i etycznymi. Naszym celem jest zapewnienie pacjentowi bezpiecznego i skutecznego leczenia, które pozwoli mu na powrót do zdrowia i normalnego życia.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-lekomanii-co-dalej">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie lekomanii – i co dalej?</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Po zakończeniu formalnego procesu leczenia, ważne jest, aby pacjent kontynuował swoją drogę do zdrowia i utrzymania trzeźwości. W AlkoMedic oferujemy wsparcie poszpitalne, które obejmuje regularne konsultacje z terapeutą, udział w grupach wsparcia oraz dostęp do programów rehabilitacyjnych. Ważnym elementem jest także edukacja pacjenta i jego rodziny na temat sposobów radzenia sobie z trudnościami i unikania nawrotów. Naszym celem jest zapewnienie pacjentowi kompleksowej opieki, która pomoże mu w utrzymaniu zdrowego i trzeźwego stylu życia na długie lata.
            </p>
        </section>
    </main>`
    },
    "hazard": {
        "title": "Hazard",
        "subTitle": "Hazard to uzależnienie, które może prowadzić do poważnych problemów finansowych i osobistych. Razem możemy odzyskać kontrolę nad życiem.",
        "contentImage": "https://images.unsplash.com/photo-1608231883522-2efb1897a608?q=80&w=1980&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        'content': `<main>
        <section>
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Spis treści</h2>
            <ul class="list-decimal my-4 mx-4">
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#wstep">Wstęp</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#hazard-informacje">Hazard – informacje</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-hazardu-objawy">Leczenie hazardu – objawy</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#jak-leczyc-hazardziste">Jak leczyć hazardzistę?</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#terapia-hazardowa">Terapia hazardowa – skuteczne leczenie uzależnienia od hazardu</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#etapy-leczenia">Etapy leczenia</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-hazardu-ile-trwa">Leczenie hazardu – ile trwa?</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#sposoby-leczenia-hazardu">Sposoby leczenia hazardu</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-hazardu-techniki">Leczenie hazardu – stosowane techniki</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#przymusowe-leczenie-hazardu">Przymusowe leczenie hazardu</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-hazardu-co-dalej">Leczenie hazardu – i co dalej?</a></li>
            </ul>
        </section>
        
        <section class="mb-8" id="wstep">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Wstęp</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Hazard to poważna choroba, która dotyka wielu ludzi na całym świecie. Jest to uzależnienie od gier losowych i zakładów, które niszczy zdrowie psychiczne oraz ma ogromny wpływ na życie społeczne i finansowe osób uzależnionych oraz ich bliskich. W AlkoMedic wierzymy, że każdy zasługuje na szansę na zdrowie i pełne, szczęśliwe życie. Dlatego oferujemy kompleksowe i indywidualne podejście do leczenia uzależnienia od hazardu, które obejmuje zarówno terapię medyczną, jak i psychologiczną.
            </p>
        </section>
        
        <section class="mb-8" id="hazard-informacje">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Hazard – informacje</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Hazard to patologiczna skłonność do uczestniczenia w grach losowych i zakładach, która prowadzi do poważnych problemów osobistych, rodzinnych i zawodowych. Uzależnienie od hazardu rozwija się stopniowo, a jego objawy mogą różnić się w zależności od osoby. Hazard nie jest wynikiem braku siły woli czy moralnego upadku, ale poważnym zaburzeniem, które wymaga specjalistycznej interwencji i leczenia.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-hazardu-objawy">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie hazardu – objawy</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Objawy uzależnienia od hazardu mogą obejmować zarówno psychiczne, jak i społeczne symptomy. Psychiczne objawy to m.in. lęk, depresja, zmiany w zachowaniu, oraz silne pragnienie uczestniczenia w grach losowych. Społeczne objawy to m.in. zaniedbywanie obowiązków zawodowych i rodzinnych, problemy finansowe, oraz problemy w relacjach interpersonalnych. Osoba uzależniona często ma trudności z kontrolowaniem ilości czasu i pieniędzy spędzanych na hazardzie. Rozpoznanie tych objawów jest kluczowe w procesie diagnozy i leczenia uzależnienia od hazardu.
            </p>
        </section>
        
        <section class="mb-8" id="jak-leczyc-hazardziste">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Jak leczyć hazardzistę?</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Leczenie uzależnienia od hazardu to proces, który wymaga zaangażowania zarówno pacjenta, jak i jego bliskich. Pierwszym krokiem jest uznanie problemu i chęć podjęcia leczenia. Następnie, konieczna jest profesjonalna ocena stanu zdrowia pacjenta oraz dobranie odpowiedniej terapii. W AlkoMedic oferujemy szeroki wachlarz metod leczenia, które są dostosowane do indywidualnych potrzeb pacjenta. Nasze podejście obejmuje zarówno terapię farmakologiczną, jak i psychoterapię, a także wsparcie socjalne i edukacyjne.
            </p>
        </section>
        
        <section class="mb-8" id="terapia-hazardowa">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Terapia hazardowa – skuteczne leczenie uzależnienia od hazardu</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Skuteczne leczenie uzależnienia od hazardu wymaga kompleksowego podejścia, które uwzględnia zarówno psychiczne, jak i społeczne aspekty uzależnienia. Terapia hazardowa w AlkoMedic obejmuje kilka kluczowych etapów, które mają na celu pomóc pacjentowi w powrocie do zdrowia. Pierwszym etapem jest terapia psychologiczna, która pomaga pacjentowi zrozumieć przyczyny uzależnienia i nauczyć się radzić sobie z trudnymi emocjami i sytuacjami bez sięgania po hazard. Kolejnym etapem jest terapia grupowa, która daje pacjentowi możliwość wsparcia i wymiany doświadczeń z innymi osobami zmagającymi się z podobnym problemem.
            </p>
        </section>
        
        <section class="mb-8" id="etapy-leczenia">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Etapy leczenia</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Leczenie uzależnienia od hazardu w AlkoMedic składa się z kilku etapów, które są dostosowane do indywidualnych potrzeb pacjenta. Pierwszym etapem jest diagnoza i ocena stanu zdrowia pacjenta, która pozwala na dobranie odpowiedniego planu leczenia. Następnie, pacjent przechodzi przez terapię indywidualną i grupową, która ma na celu zrozumienie przyczyn uzależnienia i naukę radzenia sobie z trudnymi emocjami. Kolejnym etapem jest terapia rodzinna, która angażuje bliskich pacjenta w proces leczenia i wspiera odbudowę zdrowych relacji. Ostatnim etapem jest rehabilitacja, która pomaga pacjentowi w powrocie do pełnego życia i utrzymaniu trzeźwości.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-hazardu-ile-trwa">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie hazardu – ile trwa?</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Czas trwania leczenia uzależnienia od hazardu może się różnić w zależności od indywidualnych potrzeb pacjenta oraz stopnia zaawansowania uzależnienia. W AlkoMedic oferujemy zarówno krótkoterminowe, jak i długoterminowe programy leczenia, które są dostosowane do specyficznych potrzeb pacjenta. Krótkoterminowe programy mogą trwać od kilku tygodni do kilku miesięcy, podczas gdy długoterminowe programy mogą trwać nawet rok lub dłużej. Kluczowe jest, aby leczenie było kontynuowane tak długo, jak to konieczne, aby zapewnić trwałe rezultaty i zapobiec nawrotom.
            </p>
        </section>
        
        <section class="mb-8" id="sposoby-leczenia-hazardu">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Sposoby leczenia hazardu</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Leczenie uzależnienia od hazardu w AlkoMedic obejmuje różnorodne metody i techniki, które są dostosowane do indywidualnych potrzeb pacjenta. Wśród najczęściej stosowanych metod są:
                <ul class="list-disc my-4 mx-4">
                    <li class="font-light text-gray-500 sm:text-lg">Terapia indywidualna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia grupowa</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia rodzinna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Wsparcie farmakologiczne</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia behawioralna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Programy rehabilitacyjne</li>
                </ul>
                Każdy z tych elementów leczenia jest kluczowy dla osiągnięcia trwałych rezultatów i zapewnienia pacjentowi szansy na zdrowe i pełne życie bez hazardu.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-hazardu-techniki">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie hazardu – stosowane techniki</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                W AlkoMedic stosujemy różnorodne techniki terapeutyczne, które są dostosowane do indywidualnych potrzeb pacjenta. Wśród najskuteczniejszych technik znajdują się:
                <ul class="list-disc my-4 mx-4">
                    <li class="font-light text-gray-500 sm:text-lg">Terapia poznawczo-behawioralna (CBT)</li>
                    <li class="font-light text-gray-500 sm:text-lg">Motywacyjne Rozmowy Terapeutyczne</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia Gestalt</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia systemowa</li>
                    <li class="font-light text-gray-500 sm:text-lg">Program 12 kroków</li>
                    <li class="font-light text-gray-500 sm:text-lg">Techniki mindfulness</li>
                </ul>
                Każda z tych technik ma na celu pomoc pacjentowi w zrozumieniu przyczyn uzależnienia, naukę radzenia sobie z trudnymi emocjami oraz rozwój zdrowych nawyków i umiejętności życiowych.
            </p>
        </section>
        
        <section class="mb-8" id="przymusowe-leczenie-hazardu">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Przymusowe leczenie hazardu</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                W niektórych przypadkach przymusowe leczenie uzależnienia od hazardu może być konieczne, aby chronić zdrowie i życie pacjenta oraz jego bliskich. Przymusowe leczenie jest stosowane, gdy osoba uzależniona nie jest w stanie samodzielnie podjąć decyzji o rozpoczęciu terapii, a jej stan zdrowia zagraża życiu lub zdrowiu. W AlkoMedic oferujemy profesjonalne wsparcie w zakresie przymusowego leczenia, które jest realizowane zgodnie z obowiązującymi przepisami prawnymi i etycznymi. Naszym celem jest zapewnienie pacjentowi bezpiecznego i skutecznego leczenia, które pozwoli mu na powrót do zdrowia i normalnego życia.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-hazardu-co-dalej">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie hazardu – i co dalej?</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Po zakończeniu formalnego procesu leczenia, ważne jest, aby pacjent kontynuował swoją drogę do zdrowia i utrzymania trzeźwości. W AlkoMedic oferujemy wsparcie poszpitalne, które obejmuje regularne konsultacje z terapeutą, udział w grupach wsparcia oraz dostęp do programów rehabilitacyjnych. Ważnym elementem jest także edukacja pacjenta i jego rodziny na temat sposobów radzenia sobie z trudnościami i unikania nawrotów. Naszym celem jest zapewnienie pacjentowi kompleksowej opieki, która pomoże mu w utrzymaniu zdrowego i trzeźwego stylu życia na długie lata.
            </p>
        </section>
    </main>`
    },
    "seksoholizm": {
        "title": "Seksoholizm",
        "subTitle": "Seksoholizm to uzależnienie, które może zrujnować relacje i życie osobiste. Wspólnie możemy znaleźć drogę do zdrowia i harmonii.",
        "contentImage": "https://images.unsplash.com/photo-1606103836293-0a063ee20566?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        'content': `<main>       
        <section>
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Spis treści</h2>
            <ul class="list-decimal my-4 mx-4">
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#wstep">Wstęp</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#seksoholizm-informacje">Seksoholizm – informacje</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-seksoholizmu-objawy">Leczenie seksoholizmu – objawy</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#jak-leczyc-seksoholika">Jak leczyć seksoholika?</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#terapia-seksualna">Terapia seksualna – skuteczne leczenie seksoholizmu</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#etapy-leczenia">Etapy leczenia</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-seksoholizmu-ile-trwa">Leczenie seksoholizmu – ile trwa?</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#sposoby-leczenia-seksoholizmu">Sposoby leczenia seksoholizmu</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-seksoholizmu-techniki">Leczenie seksoholizmu – stosowane techniki</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#przymusowe-leczenie-seksoholizmu">Przymusowe leczenie seksoholizmu</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-seksoholizmu-co-dalej">Leczenie seksoholizmu – i co dalej?</a></li>
            </ul>
        </section>
        
        <section class="mb-8" id="wstep">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Wstęp</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Seksoholizm to poważna choroba, która dotyka wielu ludzi na całym świecie. Jest to uzależnienie od aktywności seksualnej, które niszczy zdrowie psychiczne oraz ma ogromny wpływ na życie społeczne, emocjonalne i zawodowe osób uzależnionych oraz ich bliskich. W AlkoMedic wierzymy, że każdy zasługuje na szansę na zdrowie i pełne, szczęśliwe życie. Dlatego oferujemy kompleksowe i indywidualne podejście do leczenia uzależnienia od seksu, które obejmuje zarówno terapię medyczną, jak i psychologiczną.
            </p>
        </section>
        
        <section class="mb-8" id="seksoholizm-informacje">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Seksoholizm – informacje</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Seksoholizm to chroniczna choroba, która charakteryzuje się kompulsywnym dążeniem do aktywności seksualnej mimo negatywnych konsekwencji zdrowotnych, społecznych i emocjonalnych. Jest to zaburzenie, które rozwija się stopniowo, a jego objawy mogą różnić się w zależności od osoby. Seksoholizm nie jest wynikiem braku siły woli czy moralnego upadku, ale poważnym zaburzeniem, które wymaga specjalistycznej interwencji i leczenia.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-seksoholizmu-objawy">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie seksoholizmu – objawy</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Objawy uzależnienia od seksu mogą obejmować zarówno psychiczne, jak i społeczne symptomy. Psychiczne objawy to m.in. lęk, depresja, zmiany w zachowaniu, oraz silne pragnienie uczestniczenia w aktywnościach seksualnych. Społeczne objawy to m.in. zaniedbywanie obowiązków zawodowych i rodzinnych, problemy w relacjach interpersonalnych, oraz trudności w kontrolowaniu ilości czasu poświęcanego na aktywności seksualne. Osoba uzależniona często odczuwa silne pragnienie i kompulsywne potrzeby związane z seksem. Rozpoznanie tych objawów jest kluczowe w procesie diagnozy i leczenia uzależnienia od seksu.
            </p>
        </section>
        
        <section class="mb-8" id="jak-leczyc-seksoholika">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Jak leczyć seksoholika?</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Leczenie uzależnienia od seksu to proces, który wymaga zaangażowania zarówno pacjenta, jak i jego bliskich. Pierwszym krokiem jest uznanie problemu i chęć podjęcia leczenia. Następnie, konieczna jest profesjonalna ocena stanu zdrowia pacjenta oraz dobranie odpowiedniej terapii. W AlkoMedic oferujemy szeroki wachlarz metod leczenia, które są dostosowane do indywidualnych potrzeb pacjenta. Nasze podejście obejmuje zarówno terapię farmakologiczną, jak i psychoterapię, a także wsparcie socjalne i edukacyjne.
            </p>
        </section>
        
        <section class="mb-8" id="terapia-seksualna">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Terapia seksualna – skuteczne leczenie seksoholizmu</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Skuteczne leczenie uzależnienia od seksu wymaga kompleksowego podejścia, które uwzględnia zarówno psychiczne, jak i społeczne aspekty uzależnienia. Terapia seksualna w AlkoMedic obejmuje kilka kluczowych etapów, które mają na celu pomóc pacjentowi w powrocie do zdrowia. Pierwszym etapem jest terapia psychologiczna, która pomaga pacjentowi zrozumieć przyczyny uzależnienia i nauczyć się radzić sobie z trudnymi emocjami i sytuacjami bez angażowania się w aktywności seksualne. Kolejnym etapem jest terapia grupowa, która daje pacjentowi możliwość wsparcia i wymiany doświadczeń z innymi osobami zmagającymi się z podobnym problemem.
            </p>
        </section>
        
        <section class="mb-8" id="etapy-leczenia">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Etapy leczenia</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Leczenie uzależnienia od seksu w AlkoMedic składa się z kilku etapów, które są dostosowane do indywidualnych potrzeb pacjenta. Pierwszym etapem jest diagnoza i ocena stanu zdrowia pacjenta, która pozwala na dobranie odpowiedniego planu leczenia. Następnie, pacjent przechodzi przez terapię indywidualną i grupową, która ma na celu zrozumienie przyczyn uzależnienia i naukę radzenia sobie z trudnymi emocjami. Kolejnym etapem jest terapia rodzinna, która angażuje bliskich pacjenta w proces leczenia i wspiera odbudowę zdrowych relacji. Ostatnim etapem jest rehabilitacja, która pomaga pacjentowi w powrocie do pełnego życia i utrzymaniu zdrowego stylu życia.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-seksoholizmu-ile-trwa">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie seksoholizmu – ile trwa?</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Czas trwania leczenia uzależnienia od seksu może się różnić w zależności od indywidualnych potrzeb pacjenta oraz stopnia zaawansowania uzależnienia. W AlkoMedic oferujemy zarówno krótkoterminowe, jak i długoterminowe programy leczenia, które są dostosowane do specyficznych potrzeb pacjenta. Krótkoterminowe programy mogą trwać od kilku tygodni do kilku miesięcy, podczas gdy długoterminowe programy mogą trwać nawet rok lub dłużej. Kluczowe jest, aby leczenie było kontynuowane tak długo, jak to konieczne, aby zapewnić trwałe rezultaty i zapobiec nawrotom.
            </p>
        </section>
        
        <section class="mb-8" id="sposoby-leczenia-seksoholizmu">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Sposoby leczenia seksoholizmu</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Leczenie uzależnienia od seksu w AlkoMedic obejmuje różnorodne metody i techniki, które są dostosowane do indywidualnych potrzeb pacjenta. Wśród najczęściej stosowanych metod są:
                <ul class="list-disc my-4 mx-4">
                    <li class="font-light text-gray-500 sm:text-lg">Terapia indywidualna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia grupowa</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia rodzinna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Wsparcie farmakologiczne</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia behawioralna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Programy rehabilitacyjne</li>
                </ul>
                Każdy z tych elementów leczenia jest kluczowy dla osiągnięcia trwałych rezultatów i zapewnienia pacjentowi szansy na zdrowe i pełne życie bez uzależnienia od seksu.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-seksoholizmu-techniki">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie seksoholizmu – stosowane techniki</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                W AlkoMedic stosujemy różnorodne techniki terapeutyczne, które są dostosowane do indywidualnych potrzeb pacjenta. Wśród najskuteczniejszych technik znajdują się:
                <ul class="list-disc my-4 mx-4">
                    <li class="font-light text-gray-500 sm:text-lg">Terapia poznawczo-behawioralna (CBT)</li>
                    <li class="font-light text-gray-500 sm:text-lg">Motywacyjne Rozmowy Terapeutyczne</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia Gestalt</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia systemowa</li>
                    <li class="font-light text-gray-500 sm:text-lg">Program 12 kroków</li>
                    <li class="font-light text-gray-500 sm:text-lg">Techniki mindfulness</li>
                </ul>
                Każda z tych technik ma na celu pomoc pacjentowi w zrozumieniu przyczyn uzależnienia, naukę radzenia sobie z trudnymi emocjami oraz rozwój zdrowych nawyków i umiejętności życiowych.
            </p>
        </section>
        
        <section class="mb-8" id="przymusowe-leczenie-seksoholizmu">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Przymusowe leczenie seksoholizmu</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                W niektórych przypadkach przymusowe leczenie uzależnienia od seksu może być konieczne, aby chronić zdrowie i życie pacjenta oraz jego bliskich. Przymusowe leczenie jest stosowane, gdy osoba uzależniona nie jest w stanie samodzielnie podjąć decyzji o rozpoczęciu terapii, a jej stan zdrowia zagraża życiu lub zdrowiu. W AlkoMedic oferujemy profesjonalne wsparcie w zakresie przymusowego leczenia, które jest realizowane zgodnie z obowiązującymi przepisami prawnymi i etycznymi. Naszym celem jest zapewnienie pacjentowi bezpiecznego i skutecznego leczenia, które pozwoli mu na powrót do zdrowia i normalnego życia.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-seksoholizmu-co-dalej">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie seksoholizmu – i co dalej?</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Po zakończeniu formalnego procesu leczenia, ważne jest, aby pacjent kontynuował swoją drogę do zdrowia i utrzymania zdrowego stylu życia. W AlkoMedic oferujemy wsparcie poszpitalne, które obejmuje regularne konsultacje z terapeutą, udział w grupach wsparcia oraz dostęp do programów rehabilitacyjnych. Ważnym elementem jest także edukacja pacjenta i jego rodziny na temat sposobów radzenia sobie z trudnościami i unikania nawrotów. Naszym celem jest zapewnienie pacjentowi kompleksowej opieki, która pomoże mu w utrzymaniu zdrowego i trzeźwego stylu życia na długie lata.
            </p>
        </section>
    </main>`
    },
    "mieszane": {
        "title": "Mieszane",
        "subTitle": "Uzależnienia mieszane to złożony problem, który wymaga wszechstronnego podejścia. Razem znajdziemy drogę do zdrowia i równowagi.",
        "contentImage": "https://images.unsplash.com/photo-1472566316349-bce77aa2a778?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        'content': `<main>
        <section>
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Spis treści</h2>
            <ul class="list-decimal my-4 mx-4">
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#wstep">Wstęp</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#uzaleznienia-mieszane-informacje">Uzależnienia mieszane – informacje</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-uzaleznien-mieszanych-objawy">Leczenie uzależnień mieszanych – objawy</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#jak-leczyc-uzaleznienia-mieszane">Jak leczyć uzależnienia mieszane?</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#terapia-uzaleznien-mieszanych">Terapia uzależnień mieszanych – skuteczne leczenie</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#etapy-leczenia">Etapy leczenia</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-uzaleznien-mieszanych-ile-trwa">Leczenie uzależnień mieszanych – ile trwa?</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#sposoby-leczenia-uzaleznien-mieszanych">Sposoby leczenia uzależnień mieszanych</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-uzaleznien-mieszanych-techniki">Leczenie uzależnień mieszanych – stosowane techniki</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#przymusowe-leczenie-uzaleznien-mieszanych">Przymusowe leczenie uzależnień mieszanych</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-uzaleznien-mieszanych-co-dalej">Leczenie uzależnień mieszanych – i co dalej?</a></li>
            </ul>
        </section>
        
        <section class="mb-8" id="wstep">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Wstęp</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Uzależnienia mieszane to poważna choroba, która dotyka wielu ludzi na całym świecie. Są to uzależnienia od różnych substancji jednocześnie, takich jak alkohol, narkotyki, leki, czy hazard. W AlkoMedic wierzymy, że każdy zasługuje na szansę na zdrowie i pełne, szczęśliwe życie. Dlatego oferujemy kompleksowe i indywidualne podejście do leczenia uzależnień mieszanych, które obejmuje zarówno terapię medyczną, jak i psychologiczną.
            </p>
        </section>
        
        <section class="mb-8" id="uzaleznienia-mieszane-informacje">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Uzależnienia mieszane – informacje</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Uzależnienia mieszane to stan, w którym osoba jest uzależniona od więcej niż jednej substancji lub zachowania. Może to obejmować kombinacje takie jak alkohol i narkotyki, leki i hazard, czy inne. Takie uzależnienia często prowadzą do poważniejszych problemów zdrowotnych i społecznych, ponieważ każda z substancji lub zachowań wzajemnie potęguje swoje negatywne skutki. Uzależnienia mieszane są trudniejsze do leczenia, ponieważ wymagają zrozumienia i interwencji w wielu obszarach jednocześnie.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-uzaleznien-mieszanych-objawy">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie uzależnień mieszanych – objawy</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Objawy uzależnień mieszanych mogą obejmować zarówno fizyczne, jak i psychiczne symptomy. Fizyczne objawy to m.in. zmiany w wyglądzie, utrata wagi, problemy zdrowotne związane z konkretnymi substancjami, a także chroniczne zmęczenie. Psychiczne objawy to m.in. lęk, depresja, zmiany w zachowaniu, oraz silne pragnienie używania substancji. Społeczne objawy to m.in. zaniedbywanie obowiązków zawodowych i rodzinnych, problemy finansowe, oraz trudności w relacjach interpersonalnych. Osoba uzależniona często ma trudności z kontrolowaniem używania różnych substancji i zachowań, co prowadzi do chaosu w jej życiu.
            </p>
        </section>
        
        <section class="mb-8" id="jak-leczyc-uzaleznienia-mieszane">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Jak leczyć uzależnienia mieszane?</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Leczenie uzależnień mieszanych to złożony proces, który wymaga zaangażowania zarówno pacjenta, jak i jego bliskich. Pierwszym krokiem jest uznanie problemu i chęć podjęcia leczenia. Następnie, konieczna jest profesjonalna ocena stanu zdrowia pacjenta oraz dobranie odpowiedniej terapii. W AlkoMedic oferujemy szeroki wachlarz metod leczenia, które są dostosowane do indywidualnych potrzeb pacjenta. Nasze podejście obejmuje zarówno terapię farmakologiczną, jak i psychoterapię, a także wsparcie socjalne i edukacyjne. Ważne jest, aby leczenie było prowadzone w sposób kompleksowy, uwzględniający wszystkie aspekty uzależnienia.
            </p>
        </section>
        
        <section class="mb-8" id="terapia-uzaleznien-mieszanych">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Terapia uzależnień mieszanych – skuteczne leczenie</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Skuteczne leczenie uzależnień mieszanych wymaga kompleksowego podejścia, które uwzględnia zarówno fizyczne, jak i psychiczne aspekty uzależnienia. Terapia uzależnień mieszanych w AlkoMedic obejmuje kilka kluczowych etapów, które mają na celu pomóc pacjentowi w powrocie do zdrowia. Pierwszym etapem jest detoksykacja, która pozwala na usunięcie substancji z organizmu i złagodzenie objawów odstawienia. Kolejnym etapem jest terapia psychologiczna, która pomaga pacjentowi zrozumieć przyczyny uzależnienia i nauczyć się radzić sobie z trudnymi emocjami i sytuacjami bez używania substancji. Ważnym elementem jest także terapia grupowa, która daje pacjentowi możliwość wsparcia i wymiany doświadczeń z innymi osobami zmagającymi się z podobnym problemem.
            </p>
        </section>
        
        <section class="mb-8" id="etapy-leczenia">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Etapy leczenia</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Leczenie uzależnień mieszanych w AlkoMedic składa się z kilku etapów, które są dostosowane do indywidualnych potrzeb pacjenta. Pierwszym etapem jest diagnoza i ocena stanu zdrowia pacjenta, która pozwala na dobranie odpowiedniego planu leczenia. Następnie, pacjent przechodzi przez detoksykację, która oczyszcza organizm z substancji i łagodzi objawy odstawienia. Kolejnym etapem jest terapia indywidualna i grupowa, która ma na celu zrozumienie przyczyn uzależnienia i naukę radzenia sobie z trudnymi emocjami. Terapia rodzinna angażuje bliskich pacjenta w proces leczenia i wspiera odbudowę zdrowych relacji. Ostatnim etapem jest rehabilitacja, która pomaga pacjentowi w powrocie do pełnego życia i utrzymaniu trzeźwości.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-uzaleznien-mieszanych-ile-trwa">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie uzależnień mieszanych – ile trwa?</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Czas trwania leczenia uzależnień mieszanych może się różnić w zależności od indywidualnych potrzeb pacjenta oraz stopnia zaawansowania uzależnienia. W AlkoMedic oferujemy zarówno krótkoterminowe, jak i długoterminowe programy leczenia, które są dostosowane do specyficznych potrzeb pacjenta. Krótkoterminowe programy mogą trwać od kilku tygodni do kilku miesięcy, podczas gdy długoterminowe programy mogą trwać nawet rok lub dłużej. Kluczowe jest, aby leczenie było kontynuowane tak długo, jak to konieczne, aby zapewnić trwałe rezultaty i zapobiec nawrotom.
            </p>
        </section>
        
        <section class="mb-8" id="sposoby-leczenia-uzaleznien-mieszanych">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Sposoby leczenia uzależnień mieszanych</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Leczenie uzależnień mieszanych w AlkoMedic obejmuje różnorodne metody i techniki, które są dostosowane do indywidualnych potrzeb pacjenta. Wśród najczęściej stosowanych metod są:
                <ul class="list-disc my-4 mx-4">
                    <li class="font-light text-gray-500 sm:text-lg">Detoksykacja medyczna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia indywidualna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia grupowa</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia rodzinna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Wsparcie farmakologiczne</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia behawioralna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Programy rehabilitacyjne</li>
                </ul>
                Każdy z tych elementów leczenia jest kluczowy dla osiągnięcia trwałych rezultatów i zapewnienia pacjentowi szansy na zdrowe i pełne życie bez uzależnień.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-uzaleznien-mieszanych-techniki">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie uzależnień mieszanych – stosowane techniki</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                W AlkoMedic stosujemy różnorodne techniki terapeutyczne, które są dostosowane do indywidualnych potrzeb pacjenta. Wśród najskuteczniejszych technik znajdują się:
                <ul class="list-disc my-4 mx-4">
                    <li class="font-light text-gray-500 sm:text-lg">Terapia poznawczo-behawioralna (CBT)</li>
                    <li class="font-light text-gray-500 sm:text-lg">Motywacyjne Rozmowy Terapeutyczne</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia Gestalt</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia systemowa</li>
                    <li class="font-light text-gray-500 sm:text-lg">Program 12 kroków</li>
                    <li class="font-light text-gray-500 sm:text-lg">Techniki mindfulness</li>
                </ul>
                Każda z tych technik ma na celu pomoc pacjentowi w zrozumieniu przyczyn uzależnienia, naukę radzenia sobie z trudnymi emocjami oraz rozwój zdrowych nawyków i umiejętności życiowych.
            </p>
        </section>
        
        <section class="mb-8" id="przymusowe-leczenie-uzaleznien-mieszanych">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Przymusowe leczenie uzależnień mieszanych</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                W niektórych przypadkach przymusowe leczenie uzależnień mieszanych może być konieczne, aby chronić zdrowie i życie pacjenta oraz jego bliskich. Przymusowe leczenie jest stosowane, gdy osoba uzależniona nie jest w stanie samodzielnie podjąć decyzji o rozpoczęciu terapii, a jej stan zdrowia zagraża życiu lub zdrowiu. W AlkoMedic oferujemy profesjonalne wsparcie w zakresie przymusowego leczenia, które jest realizowane zgodnie z obowiązującymi przepisami prawnymi i etycznymi. Naszym celem jest zapewnienie pacjentowi bezpiecznego i skutecznego leczenia, które pozwoli mu na powrót do zdrowia i normalnego życia.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-uzaleznien-mieszanych-co-dalej">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie uzależnień mieszanych – i co dalej?</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Po zakończeniu formalnego procesu leczenia, ważne jest, aby pacjent kontynuował swoją drogę do zdrowia i utrzymania trzeźwości. W AlkoMedic oferujemy wsparcie poszpitalne, które obejmuje regularne konsultacje z terapeutą, udział w grupach wsparcia oraz dostęp do programów rehabilitacyjnych. Ważnym elementem jest także edukacja pacjenta i jego rodziny na temat sposobów radzenia sobie z trudnościami i unikania nawrotów. Naszym celem jest zapewnienie pacjentowi kompleksowej opieki, która pomoże mu w utrzymaniu zdrowego i trzeźwego stylu życia na długie lata.
            </p>
        </section>
    </main>`
    },
    "odtrucie_alkoholowe": {
        "title": "Odtrucie alkoholowe",
        "subTitle": "Odtrucie alkoholowe to pierwszy krok do zdrowia. Oferujemy profesjonalną pomoc w bezpiecznym i skutecznym oczyszczeniu organizmu z alkoholu.",
        "contentImage": "https://images.unsplash.com/photo-1608272667943-cbf5ee73c0fa?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        'content': `<main>        
        <section>
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Spis treści</h2>
            <ul class="list-decimal my-4 mx-4">
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#wstep">Wstęp</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#odtrucie-alkoholowe-informacje">Odtrucie alkoholowe – informacje</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-odtruwania-alkoholowego-objawy">Leczenie odtruwania alkoholowego – objawy</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#jak-leczyc-odtruwanie-alkoholowe">Jak leczyć odtruwanie alkoholowe?</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#terapia-odtruwajaca">Terapia odtruwająca – skuteczne leczenie</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#etapy-leczenia">Etapy leczenia</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-odtruwania-alkoholowego-ile-trwa">Leczenie odtruwania alkoholowego – ile trwa?</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#sposoby-leczenia-odtruwania-alkoholowego">Sposoby leczenia odtruwania alkoholowego</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-odtruwania-alkoholowego-techniki">Leczenie odtruwania alkoholowego – stosowane techniki</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#przymusowe-leczenie-odtruwania-alkoholowego">Przymusowe leczenie odtruwania alkoholowego</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-odtruwania-alkoholowego-co-dalej">Leczenie odtruwania alkoholowego – i co dalej?</a></li>
            </ul>
        </section>
        
        <section class="mb-8" id="wstep">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Wstęp</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Uzależnienie od alkoholu to poważna choroba, która dotyka wielu ludzi na całym świecie. Odtrucie alkoholowe jest pierwszym i kluczowym krokiem w procesie leczenia uzależnienia. W AlkoMedic wierzymy, że każdy zasługuje na szansę na zdrowie i pełne, szczęśliwe życie. Dlatego oferujemy kompleksowe i indywidualne podejście do odtruwania alkoholowego, które obejmuje zarówno terapię medyczną, jak i psychologiczną.
            </p>
        </section>
        
        <section class="mb-8" id="odtrucie-alkoholowe-informacje">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Odtrucie alkoholowe – informacje</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Odtrucie alkoholowe to proces oczyszczania organizmu z alkoholu i jego toksyn. Jest to kluczowy etap w leczeniu uzależnienia od alkoholu, ponieważ pozwala na bezpieczne przejście przez objawy odstawienia i przygotowuje pacjenta do dalszej terapii. Proces odtruwania alkoholowego powinien być prowadzony pod ścisłym nadzorem medycznym, aby zapewnić pacjentowi bezpieczeństwo i skuteczność leczenia. Odtrucie alkoholowe jest zazwyczaj pierwszym krokiem w długotrwałym procesie zdrowienia.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-odtruwania-alkoholowego-objawy">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie odtruwania alkoholowego – objawy</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Objawy odstawienia alkoholu mogą być bardzo różnorodne i obejmować zarówno fizyczne, jak i psychiczne symptomy. Fizyczne objawy to m.in. drżenie rąk, pocenie się, nudności, wymioty, bóle głowy oraz bóle mięśni. Psychiczne objawy to m.in. lęk, depresja, bezsenność, drażliwość oraz trudności z koncentracją. W cięższych przypadkach mogą wystąpić halucynacje, drgawki oraz delirium tremens, które stanowią bezpośrednie zagrożenie dla życia pacjenta. Rozpoznanie tych objawów jest kluczowe dla zapewnienia odpowiedniego leczenia i wsparcia w procesie odtruwania alkoholowego.
            </p>
        </section>
        
        <section class="mb-8" id="jak-leczyc-odtruwanie-alkoholowe">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Jak leczyć odtruwanie alkoholowe?</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Leczenie odtruwania alkoholowego to proces, który wymaga zaangażowania zarówno pacjenta, jak i zespołu medycznego. Pierwszym krokiem jest profesjonalna ocena stanu zdrowia pacjenta oraz dobranie odpowiedniego planu leczenia. W AlkoMedic oferujemy kompleksową opiekę medyczną, która obejmuje monitorowanie stanu zdrowia pacjenta, podawanie leków łagodzących objawy odstawienia oraz wsparcie psychologiczne. Ważne jest, aby leczenie było prowadzone w sposób bezpieczny i skuteczny, zapewniając pacjentowi komfort i wsparcie w trudnym okresie odtruwania.
            </p>
        </section>
        
        <section class="mb-8" id="terapia-odtruwajaca">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Terapia odtruwająca – skuteczne leczenie</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Skuteczna terapia odtruwająca wymaga kompleksowego podejścia, które uwzględnia zarówno fizyczne, jak i psychiczne aspekty uzależnienia. W AlkoMedic oferujemy zindywidualizowane programy terapeutyczne, które obejmują zarówno opiekę medyczną, jak i wsparcie psychologiczne. Nasze podejście obejmuje monitorowanie stanu zdrowia pacjenta, podawanie leków łagodzących objawy odstawienia, a także terapię indywidualną i grupową. Kluczowym elementem skutecznej terapii odtruwającej jest zapewnienie pacjentowi wsparcia i motywacji do dalszej walki z uzależnieniem.
            </p>
        </section>
        
        <section class="mb-8" id="etapy-leczenia">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Etapy leczenia</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Leczenie odtruwania alkoholowego w AlkoMedic składa się z kilku etapów, które są dostosowane do indywidualnych potrzeb pacjenta. Pierwszym etapem jest diagnoza i ocena stanu zdrowia pacjenta, która pozwala na dobranie odpowiedniego planu leczenia. Następnie, pacjent przechodzi przez etap detoksykacji, który oczyszcza organizm z alkoholu i łagodzi objawy odstawienia. Kolejnym etapem jest terapia indywidualna i grupowa, która ma na celu zrozumienie przyczyn uzależnienia i naukę radzenia sobie z trudnymi emocjami. Ważnym elementem jest także terapia rodzinna, która angażuje bliskich pacjenta w proces leczenia i wspiera odbudowę zdrowych relacji. Ostatnim etapem jest rehabilitacja, która pomaga pacjentowi w powrocie do pełnego życia i utrzymaniu trzeźwości.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-odtruwania-alkoholowego-ile-trwa">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie odtruwania alkoholowego – ile trwa?</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Czas trwania leczenia odtruwania alkoholowego może się różnić w zależności od indywidualnych potrzeb pacjenta oraz stopnia zaawansowania uzależnienia. W AlkoMedic oferujemy zarówno krótkoterminowe, jak i długoterminowe programy leczenia, które są dostosowane do specyficznych potrzeb pacjenta. Krótkoterminowe programy mogą trwać od kilku dni do kilku tygodni, podczas gdy długoterminowe programy mogą trwać nawet kilka miesięcy. Kluczowe jest, aby leczenie było kontynuowane tak długo, jak to konieczne, aby zapewnić trwałe rezultaty i zapobiec nawrotom.
            </p>
        </section>
        
        <section class="mb-8" id="sposoby-leczenia-odtruwania-alkoholowego">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Sposoby leczenia odtruwania alkoholowego</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Leczenie odtruwania alkoholowego w AlkoMedic obejmuje różnorodne metody i techniki, które są dostosowane do indywidualnych potrzeb pacjenta. Wśród najczęściej stosowanych metod są:
                <ul class="list-disc my-4 mx-4">
                    <li class="font-light text-gray-500 sm:text-lg">Detoksykacja medyczna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia indywidualna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia grupowa</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia rodzinna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Wsparcie farmakologiczne</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia behawioralna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Programy rehabilitacyjne</li>
                </ul>
                Każdy z tych elementów leczenia jest kluczowy dla osiągnięcia trwałych rezultatów i zapewnienia pacjentowi szansy na zdrowe i pełne życie bez alkoholu.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-odtruwania-alkoholowego-techniki">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie odtruwania alkoholowego – stosowane techniki</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                W AlkoMedic stosujemy różnorodne techniki terapeutyczne, które są dostosowane do indywidualnych potrzeb pacjenta. Wśród najskuteczniejszych technik znajdują się:
                <ul class="list-disc my-4 mx-4">
                    <li class="font-light text-gray-500 sm:text-lg">Terapia poznawczo-behawioralna (CBT)</li>
                    <li class="font-light text-gray-500 sm:text-lg">Motywacyjne Rozmowy Terapeutyczne</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia Gestalt</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia systemowa</li>
                    <li class="font-light text-gray-500 sm:text-lg">Program 12 kroków</li>
                    <li class="font-light text-gray-500 sm:text-lg">Techniki mindfulness</li>
                </ul>
                Każda z tych technik ma na celu pomoc pacjentowi w zrozumieniu przyczyn uzależnienia, naukę radzenia sobie z trudnymi emocjami oraz rozwój zdrowych nawyków i umiejętności życiowych.
            </p>
        </section>
        
        <section class="mb-8" id="przymusowe-leczenie-odtruwania-alkoholowego">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Przymusowe leczenie odtruwania alkoholowego</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                W niektórych przypadkach przymusowe leczenie odtruwania alkoholowego może być konieczne, aby chronić zdrowie i życie pacjenta oraz jego bliskich. Przymusowe leczenie jest stosowane, gdy osoba uzależniona nie jest w stanie samodzielnie podjąć decyzji o rozpoczęciu terapii, a jej stan zdrowia zagraża życiu lub zdrowiu. W AlkoMedic oferujemy profesjonalne wsparcie w zakresie przymusowego leczenia, które jest realizowane zgodnie z obowiązującymi przepisami prawnymi i etycznymi. Naszym celem jest zapewnienie pacjentowi bezpiecznego i skutecznego leczenia, które pozwoli mu na powrót do zdrowia i normalnego życia.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-odtruwania-alkoholowego-co-dalej">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie odtruwania alkoholowego – i co dalej?</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Po zakończeniu formalnego procesu leczenia, ważne jest, aby pacjent kontynuował swoją drogę do zdrowia i utrzymania trzeźwości. W AlkoMedic oferujemy wsparcie poszpitalne, które obejmuje regularne konsultacje z terapeutą, udział w grupach wsparcia oraz dostęp do programów rehabilitacyjnych. Ważnym elementem jest także edukacja pacjenta i jego rodziny na temat sposobów radzenia sobie z trudnościami i unikania nawrotów. Naszym celem jest zapewnienie pacjentowi kompleksowej opieki, która pomoże mu w utrzymaniu zdrowego i trzeźwego stylu życia na długie lata.
            </p>
        </section>
    </main>`
    },
    "odtrucie_narkotykowe": {
        "title": "Odtrucie narkotykowe",
        "subTitle": "Odtrucie narkotykowe to kluczowy etap w walce z uzależnieniem. Zapewniamy kompleksową opiekę i wsparcie, aby bezpiecznie oczyścić organizm z narkotyków.",
        "contentImage": "https://images.unsplash.com/photo-1620065692553-8d79bd124f0d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        'content': `<main>        
        <section>
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Spis treści</h2>
            <ul class="list-decimal my-4 mx-4">
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#wstep">Wstęp</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#odtrucie-narkotykowe-informacje">Odtrucie narkotykowe – informacje</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-odtruwania-narkotykowego-objawy">Leczenie odtruwania narkotykowego – objawy</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#jak-leczyc-odtruwanie-narkotykowe">Jak leczyć odtruwanie narkotykowe?</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#terapia-odtruwajaca">Terapia odtruwająca – skuteczne leczenie</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#etapy-leczenia">Etapy leczenia</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-odtruwania-narkotykowego-ile-trwa">Leczenie odtruwania narkotykowego – ile trwa?</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#sposoby-leczenia-odtruwania-narkotykowego">Sposoby leczenia odtruwania narkotykowego</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-odtruwania-narkotykowego-techniki">Leczenie odtruwania narkotykowego – stosowane techniki</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#przymusowe-leczenie-odtruwania-narkotykowego">Przymusowe leczenie odtruwania narkotykowego</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-odtruwania-narkotykowego-co-dalej">Leczenie odtruwania narkotykowego – i co dalej?</a></li>
            </ul>
        </section>
        
        <section class="mb-8" id="wstep">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Wstęp</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Uzależnienie od narkotyków to poważna choroba, która dotyka wielu ludzi na całym świecie. Odtrucie narkotykowe jest pierwszym i kluczowym krokiem w procesie leczenia uzależnienia. W AlkoMedic wierzymy, że każdy zasługuje na szansę na zdrowie i pełne, szczęśliwe życie. Dlatego oferujemy kompleksowe i indywidualne podejście do odtruwania narkotykowego, które obejmuje zarówno terapię medyczną, jak i psychologiczną.
            </p>
        </section>
        
        <section class="mb-8" id="odtrucie-narkotykowe-informacje">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Odtrucie narkotykowe – informacje</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Odtrucie narkotykowe to proces oczyszczania organizmu z narkotyków i ich toksyn. Jest to kluczowy etap w leczeniu uzależnienia od narkotyków, ponieważ pozwala na bezpieczne przejście przez objawy odstawienia i przygotowuje pacjenta do dalszej terapii. Proces odtruwania narkotykowego powinien być prowadzony pod ścisłym nadzorem medycznym, aby zapewnić pacjentowi bezpieczeństwo i skuteczność leczenia. Odtrucie narkotykowe jest zazwyczaj pierwszym krokiem w długotrwałym procesie zdrowienia.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-odtruwania-narkotykowego-objawy">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie odtruwania narkotykowego – objawy</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Objawy odstawienia narkotyków mogą być bardzo różnorodne i obejmować zarówno fizyczne, jak i psychiczne symptomy. Fizyczne objawy to m.in. drżenie rąk, pocenie się, nudności, wymioty, bóle głowy, bóle mięśni oraz problemy z układem trawiennym. Psychiczne objawy to m.in. lęk, depresja, bezsenność, drażliwość oraz trudności z koncentracją. W cięższych przypadkach mogą wystąpić halucynacje, drgawki oraz delirium, które stanowią bezpośrednie zagrożenie dla życia pacjenta. Rozpoznanie tych objawów jest kluczowe dla zapewnienia odpowiedniego leczenia i wsparcia w procesie odtruwania narkotykowego.
            </p>
        </section>
        
        <section class="mb-8" id="jak-leczyc-odtruwanie-narkotykowe">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Jak leczyć odtruwanie narkotykowe?</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Leczenie odtruwania narkotykowego to proces, który wymaga zaangażowania zarówno pacjenta, jak i zespołu medycznego. Pierwszym krokiem jest profesjonalna ocena stanu zdrowia pacjenta oraz dobranie odpowiedniego planu leczenia. W AlkoMedic oferujemy kompleksową opiekę medyczną, która obejmuje monitorowanie stanu zdrowia pacjenta, podawanie leków łagodzących objawy odstawienia oraz wsparcie psychologiczne. Ważne jest, aby leczenie było prowadzone w sposób bezpieczny i skuteczny, zapewniając pacjentowi komfort i wsparcie w trudnym okresie odtruwania.
            </p>
        </section>
        
        <section class="mb-8" id="terapia-odtruwajaca">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Terapia odtruwająca – skuteczne leczenie</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Skuteczna terapia odtruwająca wymaga kompleksowego podejścia, które uwzględnia zarówno fizyczne, jak i psychiczne aspekty uzależnienia. W AlkoMedic oferujemy zindywidualizowane programy terapeutyczne, które obejmują zarówno opiekę medyczną, jak i wsparcie psychologiczne. Nasze podejście obejmuje monitorowanie stanu zdrowia pacjenta, podawanie leków łagodzących objawy odstawienia, a także terapię indywidualną i grupową. Kluczowym elementem skutecznej terapii odtruwającej jest zapewnienie pacjentowi wsparcia i motywacji do dalszej walki z uzależnieniem.
            </p>
        </section>
        
        <section class="mb-8" id="etapy-leczenia">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Etapy leczenia</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Leczenie odtruwania narkotykowego w AlkoMedic składa się z kilku etapów, które są dostosowane do indywidualnych potrzeb pacjenta. Pierwszym etapem jest diagnoza i ocena stanu zdrowia pacjenta, która pozwala na dobranie odpowiedniego planu leczenia. Następnie, pacjent przechodzi przez etap detoksykacji, który oczyszcza organizm z narkotyków i łagodzi objawy odstawienia. Kolejnym etapem jest terapia indywidualna i grupowa, która ma na celu zrozumienie przyczyn uzależnienia i naukę radzenia sobie z trudnymi emocjami. Ważnym elementem jest także terapia rodzinna, która angażuje bliskich pacjenta w proces leczenia i wspiera odbudowę zdrowych relacji. Ostatnim etapem jest rehabilitacja, która pomaga pacjentowi w powrocie do pełnego życia i utrzymaniu trzeźwości.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-odtruwania-narkotykowego-ile-trwa">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie odtruwania narkotykowego – ile trwa?</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Czas trwania leczenia odtruwania narkotykowego może się różnić w zależności od indywidualnych potrzeb pacjenta oraz stopnia zaawansowania uzależnienia. W AlkoMedic oferujemy zarówno krótkoterminowe, jak i długoterminowe programy leczenia, które są dostosowane do specyficznych potrzeb pacjenta. Krótkoterminowe programy mogą trwać od kilku dni do kilku tygodni, podczas gdy długoterminowe programy mogą trwać nawet kilka miesięcy. Kluczowe jest, aby leczenie było kontynuowane tak długo, jak to konieczne, aby zapewnić trwałe rezultaty i zapobiec nawrotom.
            </p>
        </section>
        
        <section class="mb-8" id="sposoby-leczenia-odtruwania-narkotykowego">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Sposoby leczenia odtruwania narkotykowego</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Leczenie odtruwania narkotykowego w AlkoMedic obejmuje różnorodne metody i techniki, które są dostosowane do indywidualnych potrzeb pacjenta. Wśród najczęściej stosowanych metod są:
                <ul class="list-disc my-4 mx-4">
                    <li class="font-light text-gray-500 sm:text-lg">Detoksykacja medyczna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia indywidualna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia grupowa</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia rodzinna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Wsparcie farmakologiczne</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia behawioralna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Programy rehabilitacyjne</li>
                </ul>
                Każdy z tych elementów leczenia jest kluczowy dla osiągnięcia trwałych rezultatów i zapewnienia pacjentowi szansy na zdrowe i pełne życie bez narkotyków.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-odtruwania-narkotykowego-techniki">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie odtruwania narkotykowego – stosowane techniki</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                W AlkoMedic stosujemy różnorodne techniki terapeutyczne, które są dostosowane do indywidualnych potrzeb pacjenta. Wśród najskuteczniejszych technik znajdują się:
                <ul class="list-disc my-4 mx-4">
                    <li class="font-light text-gray-500 sm:text-lg">Terapia poznawczo-behawioralna (CBT)</li>
                    <li class="font-light text-gray-500 sm:text-lg">Motywacyjne Rozmowy Terapeutyczne</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia Gestalt</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia systemowa</li>
                    <li class="font-light text-gray-500 sm:text-lg">Program 12 kroków</li>
                    <li class="font-light text-gray-500 sm:text-lg">Techniki mindfulness</li>
                </ul>
                Każda z tych technik ma na celu pomoc pacjentowi w zrozumieniu przyczyn uzależnienia, naukę radzenia sobie z trudnymi emocjami oraz rozwój zdrowych nawyków i umiejętności życiowych.
            </p>
        </section>
        
        <section class="mb-8" id="przymusowe-leczenie-odtruwania-narkotykowego">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Przymusowe leczenie odtruwania narkotykowego</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                W niektórych przypadkach przymusowe leczenie odtruwania narkotykowego może być konieczne, aby chronić zdrowie i życie pacjenta oraz jego bliskich. Przymusowe leczenie jest stosowane, gdy osoba uzależniona nie jest w stanie samodzielnie podjąć decyzji o rozpoczęciu terapii, a jej stan zdrowia zagraża życiu lub zdrowiu. W AlkoMedic oferujemy profesjonalne wsparcie w zakresie przymusowego leczenia, które jest realizowane zgodnie z obowiązującymi przepisami prawnymi i etycznymi. Naszym celem jest zapewnienie pacjentowi bezpiecznego i skutecznego leczenia, które pozwoli mu na powrót do zdrowia i normalnego życia.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-odtruwania-narkotykowego-co-dalej">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie odtruwania narkotykowego – i co dalej?</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Po zakończeniu formalnego procesu leczenia, ważne jest, aby pacjent kontynuował swoją drogę do zdrowia i utrzymania trzeźwości. W AlkoMedic oferujemy wsparcie poszpitalne, które obejmuje regularne konsultacje z terapeutą, udział w grupach wsparcia oraz dostęp do programów rehabilitacyjnych. Ważnym elementem jest także edukacja pacjenta i jego rodziny na temat sposobów radzenia sobie z trudnościami i unikania nawrotów. Naszym celem jest zapewnienie pacjentowi kompleksowej opieki, która pomoże mu w utrzymaniu zdrowego i trzeźwego stylu życia na długie lata.
            </p>
        </section>
    </main>`
    },
    "odtrucie_lekowe": {
        "title": "Odtrucie lekowe",
        "subTitle": "Odtrucie lekowe to proces niezbędny do odzyskania równowagi zdrowotnej. Nasz ośrodek oferuje specjalistyczną opiekę i wsparcie, aby bezpiecznie przejść przez ten trudny etap.",
        "contentImage": "https://images.unsplash.com/photo-1583604310111-9cd137d6ffe5?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        'content': `<main>        
        <section>
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Spis treści</h2>
            <ul class="list-decimal my-4 mx-4">
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#wstep">Wstęp</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#odtrucie-lekowe-informacje">Odtrucie lekowe – informacje</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-odtruwania-lekowego-objawy">Leczenie odtruwania lekowego – objawy</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#jak-leczyc-odtruwanie-lekowe">Jak leczyć odtruwanie lekowe?</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#terapia-odtruwajaca">Terapia odtruwająca – skuteczne leczenie</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#etapy-leczenia">Etapy leczenia</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-odtruwania-lekowego-ile-trwa">Leczenie odtruwania lekowego – ile trwa?</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#sposoby-leczenia-odtruwania-lekowego">Sposoby leczenia odtruwania lekowego</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-odtruwania-lekowego-techniki">Leczenie odtruwania lekowego – stosowane techniki</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#przymusowe-leczenie-odtruwania-lekowego">Przymusowe leczenie odtruwania lekowego</a></li>
                <li class="font-light text-gray-500 sm:text-lg"><a class="underline hover:text-lime-600" href="#leczenie-odtruwania-lekowego-co-dalej">Leczenie odtruwania lekowego – i co dalej?</a></li>
            </ul>
        </section>
        
        <section class="mb-8" id="wstep">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Wstęp</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Uzależnienie od leków to poważna choroba, która dotyka wielu ludzi na całym świecie. Odtrucie lekowe jest pierwszym i kluczowym krokiem w procesie leczenia uzależnienia. W AlkoMedic wierzymy, że każdy zasługuje na szansę na zdrowie i pełne, szczęśliwe życie. Dlatego oferujemy kompleksowe i indywidualne podejście do odtruwania lekowego, które obejmuje zarówno terapię medyczną, jak i psychologiczną.
            </p>
        </section>
        
        <section class="mb-8" id="odtrucie-lekowe-informacje">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Odtrucie lekowe – informacje</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Odtrucie lekowe to proces oczyszczania organizmu z leków i ich toksyn. Jest to kluczowy etap w leczeniu uzależnienia od leków, ponieważ pozwala na bezpieczne przejście przez objawy odstawienia i przygotowuje pacjenta do dalszej terapii. Proces odtruwania lekowego powinien być prowadzony pod ścisłym nadzorem medycznym, aby zapewnić pacjentowi bezpieczeństwo i skuteczność leczenia. Odtrucie lekowe jest zazwyczaj pierwszym krokiem w długotrwałym procesie zdrowienia.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-odtruwania-lekowego-objawy">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie odtruwania lekowego – objawy</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Objawy odstawienia leków mogą być bardzo różnorodne i obejmować zarówno fizyczne, jak i psychiczne symptomy. Fizyczne objawy to m.in. drżenie rąk, pocenie się, nudności, wymioty, bóle głowy oraz bóle mięśni. Psychiczne objawy to m.in. lęk, depresja, bezsenność, drażliwość oraz trudności z koncentracją. W cięższych przypadkach mogą wystąpić halucynacje, drgawki oraz delirium, które stanowią bezpośrednie zagrożenie dla życia pacjenta. Rozpoznanie tych objawów jest kluczowe dla zapewnienia odpowiedniego leczenia i wsparcia w procesie odtruwania lekowego.
            </p>
        </section>
        
        <section class="mb-8" id="jak-leczyc-odtruwanie-lekowe">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Jak leczyć odtruwanie lekowe?</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Leczenie odtruwania lekowego to proces, który wymaga zaangażowania zarówno pacjenta, jak i zespołu medycznego. Pierwszym krokiem jest profesjonalna ocena stanu zdrowia pacjenta oraz dobranie odpowiedniego planu leczenia. W AlkoMedic oferujemy kompleksową opiekę medyczną, która obejmuje monitorowanie stanu zdrowia pacjenta, podawanie leków łagodzących objawy odstawienia oraz wsparcie psychologiczne. Ważne jest, aby leczenie było prowadzone w sposób bezpieczny i skuteczny, zapewniając pacjentowi komfort i wsparcie w trudnym okresie odtruwania.
            </p>
        </section>
        
        <section class="mb-8" id="terapia-odtruwajaca">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Terapia odtruwająca – skuteczne leczenie</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Skuteczna terapia odtruwająca wymaga kompleksowego podejścia, które uwzględnia zarówno fizyczne, jak i psychiczne aspekty uzależnienia. W AlkoMedic oferujemy zindywidualizowane programy terapeutyczne, które obejmują zarówno opiekę medyczną, jak i wsparcie psychologiczne. Nasze podejście obejmuje monitorowanie stanu zdrowia pacjenta, podawanie leków łagodzących objawy odstawienia, a także terapię indywidualną i grupową. Kluczowym elementem skutecznej terapii odtruwającej jest zapewnienie pacjentowi wsparcia i motywacji do dalszej walki z uzależnieniem.
            </p>
        </section>
        
        <section class="mb-8" id="etapy-leczenia">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Etapy leczenia</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Leczenie odtruwania lekowego w AlkoMedic składa się z kilku etapów, które są dostosowane do indywidualnych potrzeb pacjenta. Pierwszym etapem jest diagnoza i ocena stanu zdrowia pacjenta, która pozwala na dobranie odpowiedniego planu leczenia. Następnie, pacjent przechodzi przez etap detoksykacji, który oczyszcza organizm z leków i łagodzi objawy odstawienia. Kolejnym etapem jest terapia indywidualna i grupowa, która ma na celu zrozumienie przyczyn uzależnienia i naukę radzenia sobie z trudnymi emocjami. Ważnym elementem jest także terapia rodzinna, która angażuje bliskich pacjenta w proces leczenia i wspiera odbudowę zdrowych relacji. Ostatnim etapem jest rehabilitacja, która pomaga pacjentowi w powrocie do pełnego życia i utrzymaniu trzeźwości.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-odtruwania-lekowego-ile-trwa">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie odtruwania lekowego – ile trwa?</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Czas trwania leczenia odtruwania lekowego może się różnić w zależności od indywidualnych potrzeb pacjenta oraz stopnia zaawansowania uzależnienia. W AlkoMedic oferujemy zarówno krótkoterminowe, jak i długoterminowe programy leczenia, które są dostosowane do specyficznych potrzeb pacjenta. Krótkoterminowe programy mogą trwać od kilku dni do kilku tygodni, podczas gdy długoterminowe programy mogą trwać nawet kilka miesięcy. Kluczowe jest, aby leczenie było kontynuowane tak długo, jak to konieczne, aby zapewnić trwałe rezultaty i zapobiec nawrotom.
            </p>
        </section>
        
        <section class="mb-8" id="sposoby-leczenia-odtruwania-lekowego">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Sposoby leczenia odtruwania lekowego</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Leczenie odtruwania lekowego w AlkoMedic obejmuje różnorodne metody i techniki, które są dostosowane do indywidualnych potrzeb pacjenta. Wśród najczęściej stosowanych metod są:
                <ul class="list-disc my-4 mx-4">
                    <li class="font-light text-gray-500 sm:text-lg">Detoksykacja medyczna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia indywidualna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia grupowa</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia rodzinna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Wsparcie farmakologiczne</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia behawioralna</li>
                    <li class="font-light text-gray-500 sm:text-lg">Programy rehabilitacyjne</li>
                </ul>
                Każdy z tych elementów leczenia jest kluczowy dla osiągnięcia trwałych rezultatów i zapewnienia pacjentowi szansy na zdrowe i pełne życie bez leków.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-odtruwania-lekowego-techniki">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie odtruwania lekowego – stosowane techniki</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                W AlkoMedic stosujemy różnorodne techniki terapeutyczne, które są dostosowane do indywidualnych potrzeb pacjenta. Wśród najskuteczniejszych technik znajdują się:
                <ul class="list-disc my-4 mx-4">
                    <li class="font-light text-gray-500 sm:text-lg">Terapia poznawczo-behawioralna (CBT)</li>
                    <li class="font-light text-gray-500 sm:text-lg">Motywacyjne Rozmowy Terapeutyczne</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia Gestalt</li>
                    <li class="font-light text-gray-500 sm:text-lg">Terapia systemowa</li>
                    <li class="font-light text-gray-500 sm:text-lg">Program 12 kroków</li>
                    <li class="font-light text-gray-500 sm:text-lg">Techniki mindfulness</li>
                </ul>
                Każda z tych technik ma na celu pomoc pacjentowi w zrozumieniu przyczyn uzależnienia, naukę radzenia sobie z trudnymi emocjami oraz rozwój zdrowych nawyków i umiejętności życiowych.
            </p>
        </section>
        
        <section class="mb-8" id="przymusowe-leczenie-odtruwania-lekowego">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Przymusowe leczenie odtruwania lekowego</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                W niektórych przypadkach przymusowe leczenie odtruwania lekowego może być konieczne, aby chronić zdrowie i życie pacjenta oraz jego bliskich. Przymusowe leczenie jest stosowane, gdy osoba uzależniona nie jest w stanie samodzielnie podjąć decyzji o rozpoczęciu terapii, a jej stan zdrowia zagraża życiu lub zdrowiu. W AlkoMedic oferujemy profesjonalne wsparcie w zakresie przymusowego leczenia, które jest realizowane zgodnie z obowiązującymi przepisami prawnymi i etycznymi. Naszym celem jest zapewnienie pacjentowi bezpiecznego i skutecznego leczenia, które pozwoli mu na powrót do zdrowia i normalnego życia.
            </p>
        </section>
        
        <section class="mb-8" id="leczenie-odtruwania-lekowego-co-dalej">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Leczenie odtruwania lekowego – i co dalej?</h2>
            <p class="font-light text-gray-500 sm:text-lg">
                Po zakończeniu formalnego procesu leczenia, ważne jest, aby pacjent kontynuował swoją drogę do zdrowia i utrzymania trzeźwości. W AlkoMedic oferujemy wsparcie poszpitalne, które obejmuje regularne konsultacje z terapeutą, udział w grupach wsparcia oraz dostęp do programów rehabilitacyjnych. Ważnym elementem jest także edukacja pacjenta i jego rodziny na temat sposobów radzenia sobie z trudnościami i unikania nawrotów. Naszym celem jest zapewnienie pacjentowi kompleksowej opieki, która pomoże mu w utrzymaniu zdrowego i trzeźwego stylu życia na długie lata.
            </p>
        </section>
    </main>`
    },
    "terapia_indywidualana": {
        "title": "Terapia indywidualna",
        "subTitle": "Dedykowane podejście, które umożliwia głębsze zrozumienie i skuteczne zarządzanie osobistymi wyzwaniami związanymi z uzależnieniem.",
        "contentImage": "https://images.unsplash.com/photo-1493836512294-502baa1986e2?q=80&w=2090&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        'content': `<main>
    <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Spis treści</h2>
    <ul class="list-decimal my-4 mx-4">
        <li class="font-light text-gray-500 sm:text-lg">
            <a class="underline hover:text-lime-600" href="#czym-jest">Czym jest terapia indywidualna</a>
        </li>
        <li class="font-light text-gray-500 sm:text-lg">
            <a class="underline hover:text-lime-600" href="#na-czym-polega">Na czym polega terapia indywidualna</a>
        </li>
        <li class="font-light text-gray-500 sm:text-lg">
            <a class="underline hover:text-lime-600" href="#dla-kogo">Dla kogo jest przeznaczona terapia indywidualna</a>
        </li>
    </ul>

    <section class="mb-8" id="czym-jest">
        <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Czym jest terapia indywidualna</h2>
        <p class="font-light text-gray-500 sm:text-lg">
            Terapia indywidualna to forma psychoterapii, która koncentruje się na bezpośredniej pracy terapeuty z pacjentem. Jej celem jest zrozumienie indywidualnych problemów, emocji i myśli pacjenta oraz pomoc w ich przetwarzaniu i rozwiązaniu. W kontekście leczenia uzależnień, terapia indywidualna pozwala na głębokie zrozumienie przyczyn i skutków nałogu, a także na opracowanie skutecznych strategii radzenia sobie z trudnościami.
        </p>
    </section>

    <section class="mb-8" id="na-czym-polega">
        <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Na czym polega terapia indywidualna</h2>
        <p class="font-light text-gray-500 sm:text-lg">
            Terapia indywidualna polega na regularnych spotkaniach pacjenta z terapeutą, podczas których omawiane są różne aspekty życia pacjenta oraz jego uzależnienia. Terapeuta stosuje różne techniki psychoterapeutyczne, takie jak terapia poznawczo-behawioralna, terapia psychodynamiczna czy terapia humanistyczna, aby pomóc pacjentowi w zrozumieniu i zmianie jego zachowań, myśli i emocji. Kluczowym elementem terapii indywidualnej jest zaufanie i współpraca między pacjentem a terapeutą, co umożliwia skuteczną pracę nad problemami.
        </p>
    </section>

    <section class="mb-8" id="dla-kogo">
        <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Dla kogo jest przeznaczona terapia indywidualna</h2>
        <p class="font-light text-gray-500 sm:text-lg">
            Terapia indywidualna jest przeznaczona dla osób zmagających się z różnymi rodzajami uzależnień, w tym uzależnieniem od alkoholu, narkotyków, hazardu, czy innych substancji i zachowań. Jest również odpowiednia dla osób, które doświadczają trudności emocjonalnych, stresu, depresji, lęków czy problemów w relacjach interpersonalnych. Indywidualne podejście terapeutyczne pozwala na dostosowanie terapii do specyficznych potrzeb i sytuacji każdego pacjenta, co zwiększa jej skuteczność i szanse na trwałe wyleczenie.
        </p>
    </section></main>`
    },
    "klub_pacjenta": {
        "title": "Klub pacjenta",
        "subTitle": "Dołącz do naszego Klubu Pacjenta, gdzie znajdziesz wsparcie oraz inspirującą atmosferę do wspólnej walki z uzależnieniem.",
        "contentImage": "https://images.unsplash.com/photo-1520880867055-1e30d1cb001c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        'content': `<main><h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Spis treści</h2>
    <ul class="list-decimal my-4 mx-4">
        <li class="font-light text-gray-500 sm:text-lg">
            <a class="underline hover:text-lime-600" href="#czym-jest">Czym jest Klub Pacjenta</a>
        </li>
        <li class="font-light text-gray-500 sm:text-lg">
            <a class="underline hover:text-lime-600" href="#na-czym-polega">Na czym polega działalność Klubu Pacjenta</a>
        </li>
        <li class="font-light text-gray-500 sm:text-lg">
            <a class="underline hover:text-lime-600" href="#dla-kogo">Dla kogo jest przeznaczony Klub Pacjenta</a>
        </li>
    </ul>

    <section class="mb-8" id="czym-jest">
        <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Czym jest Klub Pacjenta</h2>
        <p class="font-light text-gray-500 sm:text-lg">
            Klub Pacjenta to specjalna inicjatywa mająca na celu wspieranie osób w procesie leczenia uzależnień oraz po zakończeniu terapii. Jest to miejsce, gdzie pacjenci mogą spotykać się, dzielić swoimi doświadczeniami, uzyskiwać wsparcie emocjonalne oraz uczestniczyć w różnorodnych zajęciach terapeutycznych i edukacyjnych. Klub Pacjenta tworzy społeczność osób, które wspólnie pracują nad utrzymaniem zdrowia i unikaniem nawrotów uzależnień.
        </p>
    </section>

    <section class="mb-8" id="na-czym-polega">
        <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Na czym polega działalność Klubu Pacjenta</h2>
        <p class="font-light text-gray-500 sm:text-lg">
            Działalność Klubu Pacjenta obejmuje regularne spotkania, warsztaty, grupy wsparcia oraz różnorodne aktywności, które mają na celu wzmacnianie zdrowych nawyków i umiejętności radzenia sobie z trudnościami. Pacjenci uczestniczą w grupowych sesjach terapeutycznych, gdzie omawiają swoje problemy i sukcesy, co pozwala na wzajemne wsparcie i motywację. Dodatkowo, Klub Pacjenta oferuje zajęcia edukacyjne, które pomagają w zrozumieniu mechanizmów uzależnienia oraz w rozwijaniu strategii zapobiegania nawrotom.
        </p>
    </section>

    <section class="mb-8" id="dla-kogo">
        <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Dla kogo jest przeznaczony Klub Pacjenta</h2>
        <p class="font-light text-gray-500 sm:text-lg">
            Klub Pacjenta jest przeznaczony dla osób, które są w trakcie leczenia uzależnień, jak i dla tych, którzy zakończyli terapię, ale potrzebują dalszego wsparcia w utrzymaniu zdrowego stylu życia. Jest to także miejsce dla rodzin i bliskich osób uzależnionych, którzy chcą lepiej zrozumieć procesy związane z uzależnieniem oraz dowiedzieć się, jak skutecznie wspierać swoich bliskich. Udział w Klubie Pacjenta pomaga w budowaniu silnej sieci wsparcia oraz w utrzymaniu motywacji do trwałej zmiany.
        </p>
    </section></main>`
    },
    "grupa_motywacyjna": {
        "title": "Grupa omtywacyjna",
        "subTitle": "Nasza Grupa Motywacyjna to bezpieczne miejsce, gdzie wzajemnie motywujemy się do pokonywania wyzwań związanych z uzależnieniem.",
        "contentImage": "https://images.unsplash.com/photo-1464207687429-7505649dae38?q=80&w=2073&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        'content': `<ain><h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Spis treści</h2>
<ul class="list-decimal my-4 mx-4">
    <li class="font-light text-gray-500 sm:text-lg">
        <a class="underline hover:text-lime-600" href="#czym-jest">Czym jest Grupa Motywacyjna</a>
    </li>
    <li class="font-light text-gray-500 sm:text-lg">
        <a class="underline hover:text-lime-600" href="#na-czym-polega">Na czym polega działalność Grupy Motywacyjnej</a>
    </li>
    <li class="font-light text-gray-500 sm:text-lg">
        <a class="underline hover:text-lime-600" href="#dla-kogo">Dla kogo jest przeznaczona Grupa Motywacyjna</a>
    </li>
</ul>
<section class="mb-8" id="czym-jest">
    <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Czym jest Grupa Motywacyjna</h2>
    <p class="font-light text-gray-500 sm:text-lg">
        Grupa Motywacyjna to forma terapii grupowej, która ma na celu wspieranie pacjentów w procesie leczenia uzależnień poprzez wzajemną motywację i wsparcie. W grupie motywacyjnej pacjenci dzielą się swoimi doświadczeniami, wyzwaniami i sukcesami, co pomaga w budowaniu poczucia wspólnoty oraz wzmacnia ich determinację do kontynuowania terapii. Grupa ta tworzy bezpieczne i wspierające środowisko, które sprzyja otwartości i zaangażowaniu w proces zdrowienia.
    </p>
</section>
<section class="mb-8" id="na-czym-polega">
    <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Na czym polega działalność Grupy Motywacyjnej</h2>
    <p class="font-light text-gray-500 sm:text-lg">
        Działalność Grupy Motywacyjnej polega na regularnych spotkaniach, podczas których uczestnicy mają okazję dzielić się swoimi przeżyciami, omawiać napotkane trudności oraz czerpać inspirację i wsparcie od innych członków grupy. Spotkania są prowadzone przez doświadczonych terapeutów, którzy moderują dyskusje i zapewniają odpowiednie narzędzia i techniki wspierające proces zdrowienia. Grupa Motywacyjna pomaga uczestnikom w identyfikacji ich mocnych stron, rozwijaniu umiejętności radzenia sobie z nałogiem oraz w budowaniu pozytywnych nawyków i zachowań.
    </p>
</section>
<section class="mb-8" id="dla-kogo">
    <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Dla kogo jest przeznaczona Grupa Motywacyjna</h2>
    <p class="font-light text-gray-500 sm:text-lg">
        Grupa Motywacyjna jest przeznaczona dla osób, które zmagają się z różnymi rodzajami uzależnień i potrzebują dodatkowego wsparcia i motywacji w procesie zdrowienia. Jest to idealne rozwiązanie dla tych, którzy chcą wzmocnić swoje zaangażowanie w terapię poprzez interakcję z innymi osobami o podobnych doświadczeniach. Grupa ta jest również odpowiednia dla osób, które zakończyły podstawowy program terapii, ale potrzebują dalszego wsparcia w utrzymaniu zdrowego stylu życia i unikaniu nawrotów. Uczestnictwo w Grupie Motywacyjnej daje możliwość budowania silnych relacji z innymi członkami grupy, co zwiększa szanse na trwałe przezwyciężenie uzależnienia.
    </p>
</section></main>`
    },
    "terapia_nawrotow": {
        "title": "Terapia nawrotów",
        "subTitle": "Terapia nawrotów w naszym ośrodku pomaga pacjentom zrozumieć i zarządzać potencjalnymi nawrotami uzależnienia, zapewniając wsparcie i strategie potrzebne do utrzymania zdrowego i stabilnego życia.",
        "contentImage": "https://images.pexels.com/photos/1134204/pexels-photo-1134204.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        'content': `<h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Spis treści</h2>
<ul class="list-decimal my-4 mx-4">
    <li class="font-light text-gray-500 sm:text-lg">
        <a class="underline hover:text-lime-600" href="#czym-jest">Czym jest Terapia Nawrotów</a>
    </li>
    <li class="font-light text-gray-500 sm:text-lg">
        <a class="underline hover:text-lime-600" href="#na-czym-polega">Na czym polega Terapia Nawrotów</a>
    </li>
    <li class="font-light text-gray-500 sm:text-lg">
        <a class="underline hover:text-lime-600" href="#dla-kogo">Dla kogo jest przeznaczona Terapia Nawrotów</a>
    </li>
</ul>
<section class="mb-8" id="czym-jest">
    <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Czym jest Terapia Nawrotów</h2>
    <p class="font-light text-gray-500 sm:text-lg">
        Terapia nawrotów to specjalistyczny program terapeutyczny, który koncentruje się na zapobieganiu i radzeniu sobie z nawrotami uzależnienia. Nawroty są częstym i naturalnym elementem procesu zdrowienia z uzależnień, dlatego ważne jest, aby pacjenci byli przygotowani na ich wystąpienie i wiedzieli, jak sobie z nimi radzić. Terapia nawrotów ma na celu wzmocnienie umiejętności radzenia sobie z trudnymi sytuacjami oraz zwiększenie motywacji do utrzymania zdrowego stylu życia.
    </p>
</section>
<section class="mb-8" id="na-czym-polega">
    <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Na czym polega Terapia Nawrotów</h2>
    <p class="font-light text-gray-500 sm:text-lg">
        Terapia nawrotów polega na regularnych sesjach terapeutycznych, które mogą być prowadzone indywidualnie lub grupowo. W trakcie tych sesji terapeuci pomagają pacjentom zidentyfikować wyzwalacze nawrotów, rozwijać strategie radzenia sobie oraz wzmacniać ich umiejętności samokontroli. Ważnym elementem terapii nawrotów jest także edukacja pacjentów na temat mechanizmów uzależnienia oraz technik zapobiegania nawrotom. Terapeuci stosują różnorodne metody, takie jak terapia poznawczo-behawioralna, techniki relaksacyjne oraz trening umiejętności społecznych, aby wspierać pacjentów w ich procesie zdrowienia.
    </p>
</section>
<section class="mb-8" id="dla-kogo">
    <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Dla kogo jest przeznaczona Terapia Nawrotów</h2>
    <p class="font-light text-gray-500 sm:text-lg">
        Terapia nawrotów jest przeznaczona dla osób, które zakończyły podstawowy program leczenia uzależnień, ale obawiają się możliwości nawrotu oraz dla tych, którzy doświadczyli nawrotu i potrzebują wsparcia w ponownym podjęciu zdrowego stylu życia. Jest również odpowiednia dla osób, które chcą wzmocnić swoje umiejętności radzenia sobie ze stresem i trudnymi emocjami oraz zwiększyć swoją motywację do kontynuowania zdrowego stylu życia. Udział w terapii nawrotów pozwala na zdobycie praktycznych narzędzi i wiedzy, które pomagają w utrzymaniu trwałej abstynencji i poprawie jakości życia.
    </p>
</section>`
    },
    "grupa_wsparcia_dla_rodzin": {
        "title": "Grupa wsparcia dla rodzin",
        "subTitle": "Nasza Grupa Wsparcia dla Rodzin oferuje wsparcie i bezpieczne środowisko do dzielenia się doświadczeniami oraz nauki skutecznych strategii wspierających zdrowienie i rehabilitację bliskich.",
        "contentImage": "https://images.pexels.com/photos/1648387/pexels-photo-1648387.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        'content': `<h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Spis treści</h2>
<ul class="list-decimal my-4 mx-4">
    <li class="font-light text-gray-500 sm:text-lg">
        <a class="underline hover:text-lime-600" href="#czym-jest">Czym jest Grupa Wsparcia dla Rodzin</a>
    </li>
    <li class="font-light text-gray-500 sm:text-lg">
        <a class="underline hover:text-lime-600" href="#na-czym-polega">Na czym polega działalność Grupy Wsparcia dla Rodzin</a>
    </li>
    <li class="font-light text-gray-500 sm:text-lg">
        <a class="underline hover:text-lime-600" href="#dla-kogo">Dla kogo jest przeznaczona Grupa Wsparcia dla Rodzin</a>
    </li>
</ul>
<section class="mb-8" id="czym-jest">
    <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Czym jest Grupa Wsparcia dla Rodzin</h2>
    <p class="font-light text-gray-500 sm:text-lg">
        Grupa Wsparcia dla Rodzin to specjalny program terapeutyczny przeznaczony dla rodzin i bliskich osób zmagających się z uzależnieniem. Celem grupy jest dostarczenie wsparcia emocjonalnego, edukacji na temat uzależnień oraz narzędzi do radzenia sobie z wyzwaniami, które mogą pojawić się w związku z uzależnieniem bliskiej osoby. Grupa ta umożliwia rodzinom dzielenie się swoimi doświadczeniami, co pomaga w budowaniu poczucia wspólnoty i zrozumienia.
    </p>
</section>
<section class="mb-8" id="na-czym-polega">
    <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Na czym polega działalność Grupy Wsparcia dla Rodzin</h2>
    <p class="font-light text-gray-500 sm:text-lg">
        Działalność Grupy Wsparcia dla Rodzin polega na regularnych spotkaniach, podczas których uczestnicy mogą otwarcie rozmawiać o swoich uczuciach, obawach i trudnościach związanych z uzależnieniem bliskiej osoby. Spotkania te są prowadzone przez doświadczonych terapeutów, którzy dostarczają rodzinom niezbędnej wiedzy na temat mechanizmów uzależnienia oraz technik radzenia sobie z jego konsekwencjami. Grupa wspiera również rozwijanie umiejętności komunikacyjnych i budowanie zdrowych relacji w rodzinie, co jest kluczowe dla wspólnego procesu zdrowienia.
    </p>
</section>
<section class="mb-8" id="dla-kogo">
    <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Dla kogo jest przeznaczona Grupa Wsparcia dla Rodzin</h2>
    <p class="font-light text-gray-500 sm:text-lg">
        Grupa Wsparcia dla Rodzin jest przeznaczona dla członków rodzin, partnerów oraz bliskich osób zmagających się z uzależnieniem. Jest to idealne rozwiązanie dla tych, którzy chcą lepiej zrozumieć problem uzależnienia, nauczyć się skutecznych metod wsparcia oraz uzyskać wsparcie emocjonalne od osób w podobnej sytuacji. Udział w grupie pozwala na zmniejszenie poczucia izolacji, poprawę relacji rodzinnych oraz wzmocnienie motywacji do wspólnego przezwyciężenia trudności związanych z uzależnieniem.
    </p>
</section>`
    },
    "inne": {
        "title": "Inne",
        "subTitle": "Znajdziesz tutaj różnorodne usługi i wsparcie, dopasowane do indywidualnych potrzeb i sytuacji naszych pacjentów.",
        "contentImage": "https://plus.unsplash.com/premium_photo-1665990294271-819b62445015?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        'content': `<h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Spis treści</h2>
<ul class="list-decimal my-4 mx-4">
    <li class="font-light text-gray-500 sm:text-lg">
        <a class="underline hover:text-lime-600" href="#programy-dodatkowe">Programy Dodatkowe</a>
    </li>
    <li class="font-light text-gray-500 sm:text-lg">
        <a class="underline hover:text-lime-600" href="#warsztaty-edukacyjne">Warsztaty Edukacyjne</a>
    </li>
    <li class="font-light text-gray-500 sm:text-lg">
        <a class="underline hover:text-lime-600" href="#uslugi-wsparcia">Usługi Wsparcia</a>
    </li>
</ul>
<section class="mb-8" id="programy-dodatkowe">
    <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Programy Dodatkowe</h2>
    <p class="font-light text-gray-500 sm:text-lg">
        Oprócz standardowych programów terapeutycznych, nasz ośrodek oferuje różnorodne programy dodatkowe, które wspierają proces zdrowienia. Są to między innymi programy rekreacyjne, arteterapia, terapia zajęciowa oraz programy sportowe. Dzięki tym zajęciom pacjenci mogą rozwijać swoje pasje, odkrywać nowe zainteresowania i budować zdrowe nawyki, które pomogą im w utrzymaniu abstynencji.
    </p>
</section>
<section class="mb-8" id="warsztaty-edukacyjne">
    <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Warsztaty Edukacyjne</h2>
    <p class="font-light text-gray-500 sm:text-lg">
        Warsztaty edukacyjne to integralna część naszej oferty, mająca na celu zwiększenie świadomości na temat uzależnień oraz rozwijanie umiejętności potrzebnych do skutecznego radzenia sobie z nimi. Oferujemy warsztaty dotyczące zdrowego stylu życia, zarządzania stresem, technik relaksacyjnych oraz umiejętności interpersonalnych. Te zajęcia pomagają pacjentom lepiej zrozumieć siebie i swoje nałogi oraz zdobyć wiedzę i narzędzia niezbędne do trwałego wyzdrowienia.
    </p>
</section>
<section class="mb-8" id="uslugi-wsparcia">
    <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Usługi Wsparcia</h2>
    <p class="font-light text-gray-500 sm:text-lg">
        Nasz ośrodek oferuje także szeroki zakres usług wsparcia, które obejmują indywidualne konsultacje terapeutyczne, coaching, wsparcie dietetyczne oraz doradztwo zawodowe. Indywidualne podejście do każdego pacjenta pozwala na dostosowanie tych usług do jego specyficznych potrzeb i celów. Dzięki temu pacjenci otrzymują kompleksową pomoc, która wspiera ich w każdym aspekcie życia i pomaga w osiągnięciu długotrwałych rezultatów.
    </p>
</section>`
    },
}

export default articles