import React from 'react'
import { ContentWithImage, Features, Footer, GalleryImages, NavBar, Team, HeroCTA, SimpleCta } from '../../components/components'
import data from '../../data/navigation.tsx'
import { Helmet } from 'react-helmet'
import { Config } from '../../config/Config.js'
const Home = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>AlkoMedic.pl - Prywatny Ośrodek Leczenia Uzależnień</title>
                <link rel="canonical" href={Config.baseURL+window.location.pathname} />
            </Helmet>
            <NavBar props={data} />
            {/* <HeroMultipleImages /> */}
            <HeroCTA props={
                {
                    title: "Prywatny Ośrodek Leczenia Uzależnień",
                    subTitle: "Odkryj drogę do zdrowia i wolności w naszym prywatnym ośrodku leczenia uzależnień <span class='font-medium font-archivoBlack font-extrabold'>ALKO<span class='text-lime-600 '>MEDIC</span></span>, gdzie troskliwa opieka spotyka się z profesjonalnym wsparciem."
                }
            } />
            <ContentWithImage />
            {/* <VideoPlayer source={"https://nowanadzieja.ct8.pl/upload/movie.mp4"} /> */}
            <Features props={
                {
                    title: "RODZAJE UZALEŻNIEŃ, KTÓRE LECZYMY",
                    subTitle: "Specjalizujemy się w leczeniu różnorodnych uzależnień, takich jak alkoholizm, narkomania, lekomania oraz problemy z hazardem i seksoholizmem. Nasz zespół terapeutów i psychologów oferuje wsparcie i indywidualne podejście, które pomagają pacjentom odzyskać zdrowie i kontrolę nad swoim życiem.",
                    content: [
                        {
                            "id": "1",
                            "title": "Alkoholizm",
                            "content": "Alkoholizm to nie tylko problem z piciem, to choroba, która dotyka całe życie. Razem możemy z nią walczyć i przywrócić równowagę oraz zdrowie.",
                            "action": "Zobacz więcej",
                            "category": "terapia",
                            "link": "alkoholizm",
                            "image": "https://images.unsplash.com/photo-1676629872240-8a123a83f0a7?q=80&w=2065&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        },
                        {
                            "id": "2",
                            "title": "Narkomania",
                            "content": "Narkomania to niebezpieczna pułapka, która niszczy życie. Nasza misja to wspólna walka i pomoc w odzyskaniu kontroli oraz zdrowia.",
                            "action": "Zobacz więcej",
                            "category": "terapia",
                            "link": "narkomania",
                            "image": "https://images.unsplash.com/photo-1529059356407-0114ed24b913?q=80&w=2111&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
                        },
                        {
                            "id": "3",
                            "title": "Lekomania",
                            "content": "Lekomania to uzależnienie, które może zniszczyć zdrowie i życie. Wspólnie możemy przezwyciężyć ten problem i wrócić do pełni sił.",
                            "action": "Zobacz więcej",
                            "category": "terapia",
                            "link": "lekomania",
                            "image": "https://images.unsplash.com/photo-1675524375058-0b0f72f5d3b8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        },
                        {
                            "id": "4",
                            "title": "Hazard",
                            "content": "Hazard to uzależnienie, które może prowadzić do poważnych problemów finansowych i osobistych. Razem możemy odzyskać kontrolę nad życiem.",
                            "action": "Zobacz więcej",
                            "category": "terapia",
                            "link": "hazard",
                            "image": "https://images.unsplash.com/photo-1608231883522-2efb1897a608?q=80&w=1980&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        },
                        {
                            "id": "5",
                            "title": "Seksoholizm",
                            "content": "Seksoholizm to uzależnienie, które może zrujnować relacje i życie osobiste. Wspólnie możemy znaleźć drogę do zdrowia i harmonii.",
                            "action": "Zobacz więcej",
                            "category": "terapia",
                            "link": "seksoholizm",
                            "image": "https://images.unsplash.com/photo-1606103836293-0a063ee20566?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        },
                        {
                            "id": "6",
                            "title": "Mieszane",
                            "content": "Uzależnienia mieszane to złożony problem, który wymaga wszechstronnego podejścia. Razem znajdziemy drogę do zdrowia i równowagi.",
                            "action": "Zobacz więcej",
                            "category": "terapia",
                            "link": "mieszane",
                            "image": "https://images.unsplash.com/photo-1472566316349-bce77aa2a778?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        }
                    ]
                }
            } />
            <br></br>
            {/* <Features props={
                {
                    title: "RODZAJE DETOKSYKACJI",
                    subTitle: "Oferujemy kompleksowe programy detoksykacji, które są kluczowym pierwszym krokiem w procesie wyzdrowienia. Nasze usługi obejmują odtrucie alkoholowe, narkotykowe i lekowe, zapewniając profesjonalną opiekę i wsparcie w bezpiecznym i skutecznym oczyszczeniu organizmu z substancji uzależniających. Dzięki naszemu doświadczeniu i indywidualnemu podejściu, pomagamy pacjentom odzyskać zdrowie fizyczne i psychiczne.",
                    content: [
                        {
                            "id": "1",
                            "title": "Odtrucie alkoholowe",
                            "content": "Odtrucie alkoholowe to pierwszy krok do zdrowia. Oferujemy profesjonalną pomoc w bezpiecznym i skutecznym oczyszczeniu organizmu z alkoholu.",
                            "action": "Zobacz więcej",
                            "category": "detoks",
                            "link": "odtrucie_alkoholowe",
                            "image": "https://images.unsplash.com/photo-1608272667943-cbf5ee73c0fa?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        },
                        {
                            "id": "2",
                            "title": "Odtrucie narkotykowe",
                            "content": "Odtrucie narkotykowe to kluczowy etap w walce z uzależnieniem. Zapewniamy kompleksową opiekę i wsparcie, aby bezpiecznie oczyścić organizm z narkotyków.",
                            "action": "Zobacz więcej",
                            "category": "detoks",
                            "link": "odtrucie_narkotykowe",
                            "image": "https://images.unsplash.com/photo-1620065692553-8d79bd124f0d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        },
                        {
                            "id": "3",
                            "title": "Odtrucie lekowe",
                            "content": "Odtrucie lekowe to proces niezbędny do odzyskania równowagi zdrowotnej. Nasz ośrodek oferuje specjalistyczną opiekę i wsparcie, aby bezpiecznie przejść przez ten trudny etap.",
                            "action": "Zobacz więcej",
                            "category": "detoks",
                            "link": "odtrucie_lekowe",
                            "image": "https://images.unsplash.com/photo-1583604310111-9cd137d6ffe5?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        }
                    ]
                }
            } /> */}
            <Team props={{
                team: [
                    {
                        id: "1",
                        fullName: "Maryna Szwajkowska",
                        position: "Terapeuta ds. uzależnień",
                        avatar: "https://images.unsplash.com/photo-1607746882042-944635dfe10e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
                        contact: {
                            facebook: "/",
                            instagram: "/",
                            phone: "555-555-555",
                        }
                    },
                    {
                        id: "2",
                        fullName: "Remigiusz Reiter",
                        position: "Psychoterapeuta uzależnień behawioralnych",
                        avatar: "https://images.unsplash.com/photo-1607990281513-2c110a25bd8c?q=80&w=2134&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
                        contact: {
                            facebook: "/",
                            instagram: "/",
                            phone: "555-555-555",
                        }
                    },
                    {
                        id: "3",
                        fullName: "Mariusz Pukas",
                        position: "Terapeuta zajęciowy",
                        avatar: "https://images.unsplash.com/photo-1557862921-37829c790f19?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
                        contact: {
                            facebook: "/",
                            instagram: "/",
                            phone: "555-555-555",
                        }
                    },
                    {
                        id: "4",
                        fullName: "Sławomir Mikołajek",
                        position: "Terapeuta rodzinny",
                        avatar: "https://images.unsplash.com/photo-1612349316228-5942a9b489c2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
                        contact: {
                            facebook: "/",
                            instagram: "/",
                            phone: "555-555-555",
                        }
                    },
                    {
                        id: "5",
                        fullName: "Celina Moczulska",
                        position: "Psycholog kliniczny",
                        avatar: "https://images.unsplash.com/photo-1559839734-2b71ea197ec2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
                        contact: {
                            facebook: "/",
                            instagram: "/",
                            phone: "555-555-555",
                        }
                    },
                    {
                        id: "6",
                        fullName: "Leokadia Szmyt",
                        position: "Terapeuta poznawczo-behawioralny",
                        avatar: "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
                        contact: {
                            facebook: "/",
                            instagram: "/",
                            phone: "555-555-555",
                        }
                    },
                ]
            }} />
            <SimpleCta props={
                {
                    title: "Dołącz do nas i zacznij odzyskiwać kontrolę nad swoim życiem",
                    subTitle: "Zachęcamy Cię do dołączenia do naszej społeczności, gdzie razem możemy rozpocząć Twoją drogę do zdrowia i wolności od uzależnień. Daj sobie szansę na profesjonalną pomoc i wsparcie dostosowane specjalnie do Twoich potrzeb.",
                }
            } />
            <GalleryImages props={{
                title: "ZOBACZ NASZ OŚRODEK",
                subTitle: "Zapraszamy do naszej galerii, gdzie możesz zobaczyć zdjęcia naszego ośrodka. Przekonaj się, jak komfortowe i spokojne jest nasze otoczenie, które zapewnia idealne warunki do zdrowienia.",
                images: [
                    {
                        id: "1",
                        src: 'https://nowanadzieja.ct8.pl/upload/1.png',
                        span: 'Lorem ipsum'
                    },
                    {
                        id: "2",
                        src: 'https://nowanadzieja.ct8.pl/upload/2.png',
                        span: 'Lorem ipsum'
                    },
                    {
                        id: "3",
                        src: 'https://nowanadzieja.ct8.pl/upload/3.png',
                        span: 'Lorem ipsum'
                    },
                    {
                        id: "4",
                        src: 'https://nowanadzieja.ct8.pl/upload/4.png',
                        span: 'Lorem ipsum'
                    },
                    {
                        id: "5",
                        src: 'https://nowanadzieja.ct8.pl/upload/5.png',
                        span: 'Lorem ipsum'
                    },
                    {
                        id: "6",
                        src: 'https://nowanadzieja.ct8.pl/upload/6.png',
                        span: 'Lorem ipsum'
                    },
                    {
                        id: "7",
                        src: 'https://nowanadzieja.ct8.pl/upload/20.png',
                        span: 'Lorem ipsum'
                    },
                    {
                        id: "8",
                        src: 'https://nowanadzieja.ct8.pl/upload/22.png',
                        span: 'Lorem ipsum'
                    },
                    {
                        id: "9",
                        src: 'https://nowanadzieja.ct8.pl/upload/23.png',
                        span: 'Lorem ipsum'
                    },
                ]
            }} />
            <Footer />
        </>
    )
}

export default Home