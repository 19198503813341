import React from 'react'
import { Footer, NavBar, SimpleHero, Features, SimpleCta } from '../../components/components'
import data from '../../data/navigation.tsx'
import { Helmet } from 'react-helmet'
import { Config } from '../../config/Config.js'
const Detox = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>AlkoMedic.pl - Detoks</title>
                <link rel="canonical" href={Config.baseURL+window.location.pathname} />
            </Helmet>
            <NavBar current={"/detoks"} props={data} />
            <SimpleHero props={
                {
                    title: "Detoks",
                    span: "Odkryj, jak nasze bezpieczne i skuteczne programy detoksykacji mogą być pierwszym krokiem w Twojej drodze do zdrowia. Zapewniamy profesjonalną opiekę i wsparcie na każdym etapie.",
                    image: "https://images.unsplash.com/photo-1454190358526-f809c8b16d98?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
            } />
            <Features props={
                {
                    title: "RODZAJE DETOKSYKACJI",
                    subTitle: "Oferujemy różnorodne metody detoksykacji, które są dostosowane do indywidualnych potrzeb naszych pacjentów. Nasz ośrodek zapewnia profesjonalną opiekę i wsparcie na każdym etapie procesu odtruwania.",
                    content: [
                        {
                            "id": "1",
                            "title": "Odtrucie alkoholowe",
                            "content": "Odtrucie alkoholowe to pierwszy krok do zdrowia. Oferujemy profesjonalną pomoc w bezpiecznym i skutecznym oczyszczeniu organizmu z alkoholu.",
                            "action": "Zobacz więcej",
                            "category": "",
                            "link": "odtrucie_alkoholowe",
                            "image": "https://images.unsplash.com/photo-1608272667943-cbf5ee73c0fa?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        },
                        {
                            "id": "2",
                            "title": "Odtrucie narkotykowe",
                            "content": "Odtrucie narkotykowe to kluczowy etap w walce z uzależnieniem. Zapewniamy kompleksową opiekę i wsparcie, aby bezpiecznie oczyścić organizm z narkotyków.",
                            "action": "Zobacz więcej",
                            "category": "",
                            "link": "odtrucie_narkotykowe",
                            "image": "https://images.unsplash.com/photo-1620065692553-8d79bd124f0d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        },
                        {
                            "id": "3",
                            "title": "Odtrucie lekowe",
                            "content": "Odtrucie lekowe to proces niezbędny do odzyskania równowagi zdrowotnej. Nasz ośrodek oferuje specjalistyczną opiekę i wsparcie, aby bezpiecznie przejść przez ten trudny etap.",
                            "action": "Zobacz więcej",
                            "category": "",
                            "link": "odtrucie_lekowe",
                            "image": "https://images.unsplash.com/photo-1583604310111-9cd137d6ffe5?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        }
                    ]
                }
            } />
            <SimpleCta props={
                {
                    title: "Dołącz do nas i zacznij odzyskiwać kontrolę nad swoim życiem",
                    subTitle: "Zachęcamy Cię do dołączenia do naszej społeczności, gdzie razem możemy rozpocząć Twoją drogę do zdrowia i wolności od uzależnień. Daj sobie szansę na profesjonalną pomoc i wsparcie dostosowane specjalnie do Twoich potrzeb.",
                }
            } />
            <Footer />
        </>
    )
}

export default Detox