import React from 'react'
import { Appointment, Button } from '../../components';
import { Link } from 'react-router-dom';
import heroBackground_image from '../../../assets/images/22.png'
const HeroCTA = ({props}) => {

    if(!props) {
        return;
    }

    const {title, subTitle} = props

    return (
        <>
            <div className="relative">
                <img src={heroBackground_image} className="absolute inset-0 h-full w-full object-cover" alt="" />
                <div className="relative bg-black bg-opacity-30">
                    {/* <svg className="absolute inset-x-0 -bottom-1 text-white" viewBox="0 0 1160 163">
                        <path fill="currentColor" d="M-164 13L-104 39.7C-44 66 76 120 196 141C316 162 436 152 556 119.7C676 88 796 34 916 13C1036 -8 1156 2 1216 7.7L1276 13V162.5H1216C1156 162.5 1036 162.5 916 162.5C796 162.5 676 162.5 556 162.5C436 162.5 316 162.5 196 162.5C76 162.5 -44 162.5 -104 162.5H-164V13Z"></path>
                    </svg> */}
                    <div className="relative mx-auto overflow-hidden px-4 py-16 sm:max-w-xl md:max-w-full md:px-24 lg:max-w-screen-xl lg:px-8 lg:py-20">
                        <div className="md:py-24 flex flex-col items-center justify-between xl:flex-row">
                            <div className="mb-12 w-full max-w-xl xl:mb-0 xl:w-7/12 xl:pr-16">
                                <h2 style={{textShadow: '1px 1px 1px #000'}} className="mb-6 max-w-lg font-sans text-3xl font-bold tracking-tight text-white sm:text-6xl sm:leading-none">{title || ""}</h2>
                                <p style={{textShadow: '1px 1px 1px #000'}} className="mb-4 max-w-xl text-base text-gray-50 md:text-lg" dangerouslySetInnerHTML={{ __html: subTitle }}></p>
                                <div className="drop-shadow-2xl">
                                {/* <Button label={"Zadzwoń już teraz (+48) 555-555-555"}/> */}
                                </div>
                            </div>
                            {/*  */}
                            <Appointment />
                            {/*  */}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default HeroCTA