import React from 'react'
import { Button } from '../../components'
import { Link } from 'react-router-dom'
import { Config } from '../../../config/Config'

const Appointment = () => {

    return (
        <>
            <div className="w-full max-w-xl xl:w-5/12 xl:px-8" 
                    data-aos="fade-up"
                    data-aos-delay="500"
                    data-aos-offset="0">
                <div className="overflow-hidden bg-white p-7 shadow-2xl sm:p-10 ">
                    <div className="mb-1 sm:mb-2">
                        <Link to={"tel:"+Config.phone.phone1}>
                            <Button type="primary" label={"Zadzwoń "+Config.phone.phone1} />
                        </Link>
                    </div>
                    <div className="mb-1 sm:mb-2 text-center">
                        <span className='md:text:md'>lub</span>
                    </div>
                    <div type="secondary" className="mb-1 sm:mb-2">
                        <Link to={"/kontakt"}>
                            <Button label={"Umów bezpłatną konsultacje"} />
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Appointment
