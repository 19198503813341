import React from 'react'
import { ContactTiles, Footer, NavBar, Payment, SimpleHero } from '../../components/components'
import data from '../../data/navigation.tsx'
import { Helmet } from 'react-helmet'
import { Config } from '../../config/Config.js'
const Contact = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>AlkoMedic.pl - Kontakt</title>
                <link rel="canonical" href={Config.baseURL + window.location.pathname} />
            </Helmet>
            <NavBar current="/kontakt" props={data} />
            <SimpleHero props={
                {
                    title: "Kontakt",
                    span: "Potrzebujesz więcej informacji? Skontaktuj się z nami, aby umówić się na konsultację, zadać pytania lub dowiedzieć się więcej o naszych usługach. Jesteśmy tutaj, aby pomóc.",
                    image: "https://images.unsplash.com/photo-1543269865-cbf427effbad?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
            } />
            <ContactTiles />
            <Payment />
            <Footer />
        </>
    )
}

export default Contact