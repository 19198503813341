import React from 'react'
import data from '../../data/navigation.tsx'
import { Features, Footer, NavBar, SimpleCta, SimpleHero } from '../../components/components'
import { Helmet } from 'react-helmet'
import { Config } from '../../config/Config.js'
const Offert = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>AlkoMedic.pl - Oferta</title>
                <link rel="canonical" href={Config.baseURL+window.location.pathname} />
            </Helmet>
            <NavBar props={data} />
            <SimpleHero props={{
                title: "Oferta",
                span: "",
                image: "https://images.unsplash.com/photo-1508873699372-7aeab60b44ab?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            }} />
            <Features props={
                {
                    title: "LECZENIE UZALEŻNIEŃ",
                    subTitle: "Specjalizujemy się w leczeniu różnorodnych uzależnień, takich jak alkoholizm, narkomania, lekomania oraz problemy z hazardem i seksoholizmem. Nasz zespół terapeutów i psychologów oferuje wsparcie i indywidualne podejście, które pomagają pacjentom odzyskać zdrowie i kontrolę nad swoim życiem.",
                    content: [
                        {
                            "id": "1",
                            "title": "Alkoholizm",
                            "content": "Alkoholizm to nie tylko problem z piciem, to choroba, która dotyka całe życie. Razem możemy z nią walczyć i przywrócić równowagę oraz zdrowie.",
                            "action": "Zobacz więcej",
                            "category": "terapia",
                            "link": "alkoholizm",
                            "image": "https://images.unsplash.com/photo-1676629872240-8a123a83f0a7?q=80&w=2065&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        },
                        {
                            "id": "2",
                            "title": "Narkomania",
                            "content": "Narkomania to niebezpieczna pułapka, która niszczy życie. Nasza misja to wspólna walka i pomoc w odzyskaniu kontroli oraz zdrowia.",
                            "action": "Zobacz więcej",
                            "category": "terapia",
                            "link": "narkomania",
                            "image": "https://images.unsplash.com/photo-1529059356407-0114ed24b913?q=80&w=2111&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
                        },
                        {
                            "id": "3",
                            "title": "Lekomania",
                            "content": "Lekomania to uzależnienie, które może zniszczyć zdrowie i życie. Wspólnie możemy przezwyciężyć ten problem i wrócić do pełni sił.",
                            "action": "Zobacz więcej",
                            "category": "terapia",
                            "link": "lekomania",
                            "image": "https://images.unsplash.com/photo-1675524375058-0b0f72f5d3b8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        },
                        {
                            "id": "4",
                            "title": "Hazard",
                            "content": "Hazard to uzależnienie, które może prowadzić do poważnych problemów finansowych i osobistych. Razem możemy odzyskać kontrolę nad życiem.",
                            "action": "Zobacz więcej",
                            "category": "terapia",
                            "link": "hazard",
                            "image": "https://images.unsplash.com/photo-1608231883522-2efb1897a608?q=80&w=1980&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        },
                        {
                            "id": "5",
                            "title": "Seksoholizm",
                            "content": "Seksoholizm to uzależnienie, które może zrujnować relacje i życie osobiste. Wspólnie możemy znaleźć drogę do zdrowia i harmonii.",
                            "action": "Zobacz więcej",
                            "category": "terapia",
                            "link": "seksoholizm",
                            "image": "https://images.unsplash.com/photo-1606103836293-0a063ee20566?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        },
                        {
                            "id": "6",
                            "title": "Mieszane",
                            "content": "Uzależnienia mieszane to złożony problem, który wymaga wszechstronnego podejścia. Razem znajdziemy drogę do zdrowia i równowagi.",
                            "action": "Zobacz więcej",
                            "category": "terapia",
                            "link": "mieszane",
                            "image": "https://images.unsplash.com/photo-1472566316349-bce77aa2a778?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        }
                    ]
                }
            } />
            <br></br>
            <Features props={
                {
                    title: "DETOKSYKACJA",
                    subTitle: "Oferujemy kompleksowe programy detoksykacji, które są kluczowym pierwszym krokiem w procesie wyzdrowienia. Nasze usługi obejmują odtrucie alkoholowe, narkotykowe i lekowe, zapewniając profesjonalną opiekę i wsparcie w bezpiecznym i skutecznym oczyszczeniu organizmu z substancji uzależniających. Dzięki naszemu doświadczeniu i indywidualnemu podejściu, pomagamy pacjentom odzyskać zdrowie fizyczne i psychiczne.",
                    content: [
                        {
                            "id": "1",
                            "title": "Odtrucie alkoholowe",
                            "content": "Odtrucie alkoholowe to pierwszy krok do zdrowia. Oferujemy profesjonalną pomoc w bezpiecznym i skutecznym oczyszczeniu organizmu z alkoholu.",
                            "action": "Zobacz więcej",
                            "category": "detoks",
                            "link": "odtrucie_alkoholowe",
                            "image": "https://images.unsplash.com/photo-1608272667943-cbf5ee73c0fa?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        },
                        {
                            "id": "2",
                            "title": "Odtrucie narkotykowe",
                            "content": "Odtrucie narkotykowe to kluczowy etap w walce z uzależnieniem. Zapewniamy kompleksową opiekę i wsparcie, aby bezpiecznie oczyścić organizm z narkotyków.",
                            "action": "Zobacz więcej",
                            "category": "detoks",
                            "link": "odtrucie_narkotykowe",
                            "image": "https://images.unsplash.com/photo-1620065692553-8d79bd124f0d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        },
                        {
                            "id": "3",
                            "title": "Odtrucie lekowe",
                            "content": "Odtrucie lekowe to proces niezbędny do odzyskania równowagi zdrowotnej. Nasz ośrodek oferuje specjalistyczną opiekę i wsparcie, aby bezpiecznie przejść przez ten trudny etap.",
                            "action": "Zobacz więcej",
                            "category": "detoks",
                            "link": "odtrucie_lekowe",
                            "image": "https://images.unsplash.com/photo-1583604310111-9cd137d6ffe5?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        }
                    ]
                }
            } />
            <Features props={
                {
                    title: "WSPARCIE - TERAPIA",
                    subTitle: "Nasz ośrodek oferuje różnorodne formy wsparcia, które są dostosowane do potrzeb naszych pacjentów. Wspieramy ich poprzez grupy wsparcia dla rodzin, klub pacjenta oraz grupy motywacyjne, tworząc bezpieczne środowisko sprzyjające zdrowieniu i powrotowi do pełni życia.",
                    content: [
                        {
                            "id": "1",
                            "title": "Terapia indywidualna",
                            "content": "Dedykowane podejście, które umożliwia głębsze zrozumienie i skuteczne zarządzanie osobistymi wyzwaniami związanymi z uzależnieniem.",
                            "action": "Zobacz więcej",
                            "category": "wsparcie",
                            "link": "terapia_indywidualana",
                            "image": "https://images.unsplash.com/photo-1493836512294-502baa1986e2?q=80&w=2090&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        },
                        {
                            "id": "2",
                            "title": "Klub pacjenta",
                            "content": "Dołącz do naszego Klubu Pacjenta, gdzie znajdziesz wsparcie oraz inspirującą atmosferę do wspólnej walki z uzależnieniem.",
                            "action": "Zobacz więcej",
                            "category": "wsparcie",
                            "link": "klub_pacjenta",
                            "image": "https://images.unsplash.com/photo-1520880867055-1e30d1cb001c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        },
                        {
                            "id": "3",
                            "title": "Grupa motywacyjna",
                            "content": "Nasza Grupa Motywacyjna to bezpieczne miejsce, gdzie wzajemnie motywujemy się do pokonywania wyzwań związanych z uzależnieniem.",
                            "action": "Zobacz więcej",
                            "category": "wsparcie",
                            "link": "grupa_motywacyjna",
                            "image": "https://images.unsplash.com/photo-1464207687429-7505649dae38?q=80&w=2073&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        },
                        {
                            "id": "4",
                            "title": "Terapia nawrotów",
                            "content": "Terapia nawrotów w naszym ośrodku pomaga pacjentom zrozumieć i zarządzać potencjalnymi nawrotami uzależnienia, zapewniając wsparcie i strategie potrzebne do utrzymania zdrowego i stabilnego życia.",
                            "action": "Zobacz więcej",
                            "category": "wsparcie",
                            "link": "terapia_nawrotow",
                            "image": "https://images.pexels.com/photos/1134204/pexels-photo-1134204.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                        },
                        {
                            "id": "5",
                            "title": "Grupa wsparcia dla rodzin",
                            "content": "Nasza Grupa Wsparcia dla Rodzin oferuje wsparcie i bezpieczne środowisko do dzielenia się doświadczeniami oraz nauki skutecznych strategii wspierających zdrowienie i rehabilitację bliskich.",
                            "action": "Zobacz więcej",
                            "category": "wsparcie",
                            "link": "grupa_wsparcia_dla_rodzin",
                            "image": "https://images.pexels.com/photos/1648387/pexels-photo-1648387.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                        },
                        {
                            "id": "6",
                            "title": "Inne",
                            "content": "Znajdziesz tutaj różnorodne usługi i wsparcie, dopasowane do indywidualnych potrzeb i sytuacji naszych pacjentów.",
                            "action": "Zobacz więcej",
                            "category": "wsparcie",
                            "link": "inne",
                            "image": "https://plus.unsplash.com/premium_photo-1665990294271-819b62445015?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        },
                    ]
                }
            } />
            <SimpleCta props={
                {
                    title: "Dołącz do nas i zacznij odzyskiwać kontrolę nad swoim życiem",
                    subTitle: "Zachęcamy Cię do dołączenia do naszej społeczności, gdzie razem możemy rozpocząć Twoją drogę do zdrowia i wolności od uzależnień. Daj sobie szansę na profesjonalną pomoc i wsparcie dostosowane specjalnie do Twoich potrzeb.",
                }
            } />
            <Footer />
        </>
    )
}

export default Offert