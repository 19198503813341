import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { Button } from '../../components';
import logo from '../../../assets/images/logo.svg'
import { Config } from '../../../config/Config.js'
const NavBar = ({ props, current }) => {

    const [menuOpen, setMenuOpen] = useState(false);

    if (!props) return;

    const { navigationLinks } = props;

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    return (
        <header className="border-b sticky top-0 backdrop-blur-sm bg-white/90 z-50">
            <div className="mx-auto flex items-center md:items-stretch justify-between pl-4 md:pl-8 h-16 sm:h-24">

                <Link to="/" className="inline-flex items-center gap-2.5 text-2xl font-bold text-black md:text-3xl" aria-label="logo">
                    <div className='flex flex-col'>
                        <span className='font-archivoBlack font-extrabold text-2xl md:text-3xl'>ALKO<span className='text-lime-600'>MEDIC</span></span>
                        <span className='text-sm font-normal text-gray-600'>Ośrodek Terapii Uzależnień</span>
                    </div>
                    {/* <img src={logo} /> */}
                </Link>

                <nav className={`${!menuOpen && "hidden"} flex shadow-2xl items-center lg:shadow-none absolute top-16 left-0 right-0 w-full px-4 sm:top-24 md:px-8 lg:flex flex-col bg-white lg:bg-transparent lg:gap-12 lg:flex-row lg:static lg:w-auto 2xl:ml-16`}>
                    {navigationLinks.map((element) => {
                        return <Link onClick={() => { toggleMenu() }} key={element.id} to={element.href} className={`${element.href === current && "border-b border-solid border-lime-700 text-lime-700 "} hover:border-b text-gray-800 hover:border-solid border-lime-700 text-md font-medium transition duration-100 hover:text-lime-700 active:text-lime-700 py-4 lg:py-0 uppercase`}>{element.label}</Link>
                    })}
                </nav>

                <div className='hidden xl:block'>
                    <Link to={"tel:" + Config.phone.phone1}>
                        <Button className={'h-full text-lg'} label={"Zadzwoń: " + Config.phone.phone1} />
                    </Link>
                </div>

                <button
                    onClick={() => { toggleMenu() }}
                    type="button" className="flex h-12 w-12 flex-col items-center justify-center gap-1.5 transition duration-100 hover:bg-gray-100 active:bg-gray-200 sm:h-20 sm:w-20 md:h-24 md:w-24 lg:hidden">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-800" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                    </svg>
                </button>
            </div>
            <div className='block xl:hidden'>
                <Link to={"tel:" + Config.phone.phone1}>
                    <Button label={"Tel: " + Config.phone.phone1} />
                </Link>
            </div>
        </header>

    )
}

export default NavBar