import React from 'react'
import { Alert } from '../../components';

const SimpleHero = ({ props }) => {

    if (!props) {
        return (<div className='px-24 mx-auto'>
            <Alert title='Error' message='Missing props in SimpleHero component.' type='error' visible={true} />
        </div>)
    }

    const { title, span, image } = props;

    return (
        <div className="relative flex flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0">
            <div className="inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
                <svg className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block" viewBox="0 0 100 100" fill="currentColor" preserveAspectRatio="none slice">
                    <path d="M50 0H100L50 100H0L50 0Z"></path>
                </svg>
                <img
                    className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
                    src={image}
                    alt={title}
                />
            </div>
            <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
                <header className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5">
                    <h1 className="mb-6 max-w-lg font-sans text-3xl font-bold tracking-tight sm:text-6xl sm:leading-none">
                        {title}
                    </h1>
                    <p className="max-w-md leading-relaxed text-gray-500 font-light xl:text-lg">
                        {span}
                    </p>
                </header>
            </div>
        </div>
    )
}

export default SimpleHero