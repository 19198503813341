import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className="bg-white">
      <div className="pb-16 pt-4 sm:pt-10 lg:pt-12">
        <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
          <div className="text-center md:text-left grid grid-cols-1 gap-12 border-t pt-10 md:grid-cols-2 lg:grid-cols-5 lg:gap-8 lg:pt-12">
            <div className="col-span-full lg:col-span-2">
              <div className="mb-4 lg:-mt-2">
                <Link to="/" className="inline-flex items-center gap-2 text-xl font-bold text-black md:text-2xl" aria-label="logo">
                  <div className='flex flex-col'>
                        <span className='text-2xl md:text-3xl font-archivoBlack font-extrabold'>ALKO<span className='text-lime-600'>MEDIC</span></span>
                        <span className='text-sm font-normal text-gray-600'>Ośrodek Terapii Uzależnień</span>
                    </div>
                </Link>
              </div>

              {/* <p className="text-gray-500 sm:pr-8">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p> */}
            </div>

            <div>
              <div className="mb-4 font-bold uppercase tracking-widest text-gray-800">Uzaleznienia</div>

              <nav className="flex flex-col gap-4">
                <div>
                  <Link to={"/terapia/alkoholizm"} className="text-gray-500 transition duration-100 hover:text-lime-700 active:text-lime-700">Alkoholizm</Link>
                </div>

                <div>
                  <Link to={"/terapia/narkomania"} className="text-gray-500 transition duration-100 hover:text-lime-700 active:text-lime-700">Narkomania</Link>
                </div>

                <div>
                  <Link to={"/terapia/lekomania"} className="text-gray-500 transition duration-100 hover:text-lime-700 active:text-lime-700">Lekomania</Link>
                </div>

                <div>
                  <Link to={"/terapia/hazard"} className="text-gray-500 transition duration-100 hover:text-lime-700 active:text-lime-700">Hazard</Link>
                </div>
                <div>
                  <Link to={"/terapia/seksoholizm"} className="text-gray-500 transition duration-100 hover:text-lime-700 active:text-lime-700">Seksoholizm</Link>
                </div>
                <div>
                  <Link to={"/terapia/inne"} className="text-gray-500 transition duration-100 hover:text-lime-700 active:text-lime-700">Inne</Link>
                </div>
              </nav>
            </div>

            {/* <div>
              <div className="mb-4 font-bold uppercase tracking-widest text-gray-800">Detoks</div>

              <nav className="flex flex-col gap-4">
                <div>
                  <Link to={"#"} className="text-gray-500 transition duration-100 hover:text-lime-700 active:text-lime-700">Alkoholowy</Link>
                </div>

                <div>
                  <Link to={"#"} className="text-gray-500 transition duration-100 hover:text-lime-700 active:text-lime-700">Narkotykowy</Link>
                </div>

                <div>
                  <Link to={"#"} className="text-gray-500 transition duration-100 hover:text-lime-700 active:text-lime-700">Lekowy</Link>
                </div>

              </nav>
            </div> */}

            <div>
              <div className="mb-4 font-bold uppercase tracking-widest text-gray-800">Wsparcie</div>

              <nav className="flex flex-col gap-4">
                <div>
                  <Link to={"#"} className="text-gray-500 transition duration-100 hover:text-lime-700 active:text-lime-700">Terapia indywidualna</Link>
                </div>

                <div>
                  <Link to={"#"} className="text-gray-500 transition duration-100 hover:text-lime-700 active:text-lime-700">Klub pacjenta</Link>
                </div>

                <div>
                  <Link to={"#"} className="text-gray-500 transition duration-100 hover:text-lime-700 active:text-lime-700">Grupa motywacyjna</Link>
                </div>

                <div>
                  <Link to={"#"} className="text-gray-500 transition duration-100 hover:text-lime-700 active:text-lime-700">Terapia nawrotów</Link>
                </div>
                <div>
                  <Link to={"#"} className="text-gray-500 transition duration-100 hover:text-lime-700 active:text-lime-700">Grupa wsprci dla rodzin</Link>
                </div>
                <div>
                  <Link to={"#"} className="text-gray-500 transition duration-100 hover:text-lime-700 active:text-lime-700">Inne</Link>
                </div>
              </nav>
            </div>

            <div>
              <div className="mb-4 font-bold uppercase tracking-widest text-gray-800">Kontakt</div>

              <nav className="flex flex-col gap-4">
                <div>
                  <Link to={"#"} className="text-gray-500 transition duration-100 hover:text-lime-700 active:text-lime-700">Rezerwacja terminu</Link>
                </div>

                <div>
                  <Link to={"#"} className="text-gray-500 transition duration-100 hover:text-lime-700 active:text-lime-700">Umów się na rozmowę</Link>
                </div>

                <div>
                  <Link to={"#"} className="text-gray-500 transition duration-100 hover:text-lime-700 active:text-lime-700">Pomoc w decyzji</Link>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-100">
        <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
          <div className="flex items-center justify-between gap-4 py-8">
            <span className="text-sm text-gray-400"> © 2024 by Alkomedic.pl</span>

            <div className="flex gap-4">

              <Link to={"#"} target="_blank" className="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
                <svg className="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clipRule="evenodd"></path>
                </svg>
              </Link>
              <Link to={"#"} target="_blank" className="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
                <svg className="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clipRule="evenodd"></path>
                </svg>
              </Link>

            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer