import React from 'react'
import { Button } from '../../components'

const ContactTiles = () => {
    return (
        <div className='mx-auto max-w-screen-lg'>
            <div className="bg-white py-6 sm:py-8 lg:py-12 border-b">
                <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
                    <p className="mb-2 font-semibold text-lime-500 md:mb-3 lg:text-lg">Aktywna</p>

                    <div className="bg-slate-50 p-4 flex items-center justify-between mb-4 md:mb-6">
                        <h2 className="text-2xl text-gray-800 lg:text-3xl">Infolinia</h2>
                        <p className="text-xl font-medium">pon.-pt. 08:00 - 16:00</p>
                    </div>

                    <div className="mt-6 grid gap-4 sm:grid-cols-2 md:gap-8 xl:grid-cols-3 ">
                        <div className="flex flex-col  border p-4 md:p-6">
                            <h3 className="mb-2 text-lg font-semibold md:text-xl">Biuro</h3>
                            <p className="mb-4 text-gray-500">Skontaktuj się z naszym biurem, dzwoniąc na infolinię. Jesteśmy zawsze gotowi, aby Ci pomóc.</p>
                            <a href="#" className="mt-auto text-slate-500 transition duration-100 hover:text-slate-600 active:text-slate-700 text-lg"><span className='text-white font-medium bg-gray-500 px-2 py-1'>+48 555-555-550</span></a>
                        </div>
                        <div className="flex flex-col  border p-4 md:p-6">
                            <h3 className="mb-2 text-lg font-semibold md:text-xl">Pomoc i obsługa</h3>
                            <p className="mb-4 text-gray-500">Nasza infolinia jest dostępna, aby zapewnić wsparcie i odpowiedzi na wszystkie Twoje pytania.</p>
                            <a href="#" className="mt-auto text-slate-500 transition duration-100 hover:text-slate-600 active:text-slate-700 text-lg"><span className='text-white font-medium bg-gray-500 px-2 py-1'>+48 555-555-551</span></a>
                        </div>
                        <div className="flex flex-col  border p-4 md:p-6">
                            <h3 className="mb-2 text-lg font-semibold md:text-xl">Oferta</h3>
                            <p className="mb-4 text-gray-500">Dowiedz się więcej o naszej ofercie, kontaktując się z nami przez infolinię. Zawsze służymy pomocą.</p>
                            <a href="#" className="mt-auto text-slate-500 transition duration-100 hover:text-slate-600 active:text-slate-700 text-lg"><span className='text-white font-medium bg-gray-500 px-2 py-1'>+48 555-555-552</span></a>
                        </div>
                    </div>

                </div>
            </div>

            <div className="bg-white py-6 sm:py-8 lg:py-12 border-b">
                <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
                    <p className="mb-2 font-semibold text-lime-500 md:mb-3 lg:text-lg">Aktywna</p>

                    <div className="bg-slate-50 p-4 flex items-center justify-between mb-4 md:mb-6">
                        <h2 className="text-2xl text-gray-800 lg:text-3xl">E-mail</h2>
                        <p className="text-xl font-medium">pon.-pt. 08:00 - 16:00</p>
                    </div>

                    <div className="mt-6 grid gap-4 sm:grid-cols-1 md:gap-8 xl:grid-cols-3 ">
                        <div className="flex flex-col  border p-4 md:p-6">
                            <h3 className="mb-2 text-lg font-semibold md:text-xl">Pomoc i obsługa</h3>
                            <p className="mb-4 text-gray-500">W przypadku pytań lub potrzeby wsparcia, napisz do nas na adres:</p>
                            <a href="#" className="mt-auto text-slate-500 transition duration-100 hover:text-slate-600 active:text-slate-700 text-lg"><span className='text-white font-medium bg-gray-500 px-2 py-1'>pomoc@alkomedic.pl</span></a>
                        </div>
                        <div className="flex flex-col  border p-4 md:p-6">
                            <h3 className="mb-2 text-lg font-semibold md:text-xl">Oferta</h3>
                            <p className="mb-4 text-gray-500">
                                Jeśli masz pytania dotyczące naszej oferty, napisz do nas na adres:</p>
                            <a href="#" className="mt-auto text-slate-500 transition duration-100 hover:text-slate-600 active:text-slate-700 text-lg"><span className='text-white font-medium bg-gray-500 px-2 py-1'>oferta@alkomedic.pl</span></a>
                        </div>
                    </div>

                </div>
            </div>

            <div className="bg-white py-6 sm:py-8 lg:py-12 border-b">
                <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
                    <p className="mb-2 font-semibold text-lime-500 md:mb-3 lg:text-lg">Aktywna</p>

                    <div className="bg-slate-50 p-4 flex items-center justify-between mb-4 md:mb-6">
                        <h2 className="text-2xl text-gray-800 lg:text-3xl">Pilna pomoc</h2>
                        <p className="text-xl font-medium">pon.-niedz. 24/7</p>
                    </div>

                    <div className="mt-6 grid gap-4 sm:grid-cols-1 md:gap-8 xl:grid-cols-3 ">
                        <div className="flex flex-col  border p-4 md:p-6">
                            <h3 className="mb-2 text-lg font-semibold md:text-xl">Pomoc i obsługa</h3>
                            <p className="mb-4 text-gray-500">W przypadku pilnej pomocy, natychmiast skontaktuj się z nami przez infolinię. Jesteśmy dostępni, aby szybko rozwiązać Twoje problemy.</p>
                            <a href="#" className="mt-auto text-slate-500 transition duration-100 hover:text-slate-600 active:text-slate-700 text-lg"><span className='text-white font-medium bg-gray-500 px-2 py-1'>+48 555-555-555</span></a>
                        </div>
                    </div>

                </div>
            </div>

            <div className="bg-white py-6 sm:py-8 lg:py-12">
                <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
                    {/* <p className="mb-2 font-semibold text-slate-500 md:mb-3 lg:text-lg">Adres</p> */}

                    <h2 className="mb-4 text-2xl  text-gray-800 md:mb-6 lg:text-3xl">Adres</h2>

                    <div className="flex">
                        <p className="bg-gray-500 text-white px-2 py-1  text-sm">pon.-pt. 08:00 - 16:00</p>
                    </div>

                    <div className="mt-6 grid gap-4 sm:grid-cols-1 md:gap-8 xl:grid-cols-1 ">
                        <div className="flex flex-col p-4 md:p-6">
                            <h3 className="mb-2 text-lg font-semibold md:text-xl">Jasło</h3>
                            <p className="mb-4 text-gray-500">Jasło, ul. Tadeusza Kosciuszki 56,<br /> 83-211 Jasło, woj. małopolskie</p>
                            <iframe className="w-full h-[350px] border-0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4336.514245351328!2d21.471648150228592!3d49.73890731498014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473db40ad0d97001%3A0x1512bd6fe2024e70!2zSmFzxYJv!5e0!3m2!1sen!2spl!4v1723813673158!5m2!1sen!2spl" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ContactTiles